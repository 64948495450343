import React from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Box,
  Flex,
  Icon,
  Spacer,
} from "@chakra-ui/react";

const StatCard = ({
  primaryValue,
  secondaryValue,
  scoreCardTitle,
  ...rest
}) => {
  return (
    <Box
      bgColor="white"
      h="90%"
      pt="0.5%"
      pb="0.5%"
      borderRadius="lg"
      boxShadow={"md"}
    >
      <Stat m="10%">
        <Flex>
          <Box w="fit-content">
            <StatLabel color="gray.400" w="110%" textAlign="justify">
              {scoreCardTitle}
            </StatLabel>
            <StatNumber fontWeight="800" mt="10%" textAlign="justify">
              {primaryValue}
            </StatNumber>
          </Box>
          <Spacer />
          <Box>
            <Box mt="25%">
              <StatHelpText fontWeight="800" textColor="#FF3131">
                {secondaryValue}
              </StatHelpText>
            </Box>
          </Box>
        </Flex>
      </Stat>
    </Box>
  );
};

export default StatCard;
