import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Select as ChakraSelect,
} from "@chakra-ui/react";

const TemplateModal = ({
  buttonText,
  onClose,
  setSelectedTemplate,
  children,
  ...props
}) => {
  const Templates = [
    {
      id: 1,
      template: "Template 1",
      value: "template1",
    },
    {
      id: 2,
      template: "Template 2",
      value: "template2",
    },
  ];

  const scoreCard = [
    {
      text: "score card",
    },
    {
      text: "score card",
    },
    {
      text: "score card",
    },
    {
      text: "score card",
    },
    {
      text: "score card",
    },
  ];

  const graphs = [
    {
      text: "score card",
    },
    {
      text: "score card",
    },
  ];

  const tables = [
    {
      text: "score card",
    },
    {
      text: "score card",
    },
  ];

  const [template, setTemplate] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const handleAddClick = (id) => {
    let obj = Templates.find((o) => o.id === id);
    setTemplate(obj.value);
    setTemplateName(obj.template);
    console.log("type obj--------->", obj.template);
  };

  return (
    <>
      <Box d="flex" flexDir="column" justifyContent="center">
        <Flex borderColor="#EEEEEE" borderWidth="2px">
          <Box w="30%" borderRightWidth="1px" borderColor="#EEEEEE">
            {Templates.map((template) => (
              <Flex
                borderWidth="1px"
                py="4%"
                px="3%"
                borderColor="#EEEEEE"
                _hover={{ backgroundColor: "gray" }}
                onClick={() => handleAddClick(template.id)}
              >
                <Text ml="3%" mt="0%" fontSize="lg" color="black">
                  {template.template}
                </Text>
              </Flex>
            ))}
          </Box>
          <Box w="70%" h="60vh" overflowY="auto">
            <Text fontSize="xl" fontWeight="bold" my="1%" mx="2%">
              {templateName}
            </Text>
            {template === "template1" ? (
              <Box my="2%" mx="1%">
                <Text fontSize="lg" fontWeight="bold" my="1%" mx="2%">
                  Score cards:
                </Text>
                <Flex justify="space-between">
                  {scoreCard.map((scoreCard) => (
                    <Box
                      py="6%"
                      w="19%"
                      mx="1%"
                      borderWidth="1px"
                      borderRadius="md"
                      bgColor="gray.300"
                      h="10vh"
                      textAlign="center"
                    >
                      <Text>Score card</Text>
                    </Box>
                  ))}
                </Flex>
                <Text fontSize="lg" fontWeight="bold" my="1%" mx="2%">
                  Graphs:
                </Text>
                <Flex my="2%" justify="space-between">
                  {graphs.map((graph) => (
                    <Box
                      py="13%"
                      w="49%"
                      mx="1%"
                      borderWidth="1px"
                      borderRadius="md"
                      bgColor="gray.300"
                      h="20vh"
                      textAlign="center"
                    >
                      <Text>Graphs</Text>
                    </Box>
                  ))}
                </Flex>
                <Text fontSize="lg" fontWeight="bold" my="1%" mx="2%">
                  Tables:
                </Text>
                <Flex my="2%" justify="space-between">
                  {tables.map((graph) => (
                    <Box
                      py="13%"
                      w="49%"
                      mx="1%"
                      borderWidth="1px"
                      borderRadius="md"
                      bgColor="gray.300"
                      h="20vh"
                      textAlign="center"
                    >
                      <Text>Tables</Text>
                    </Box>
                  ))}
                </Flex>
              </Box>
            ) : (
              <Box my="2%" mx="1%">
                <Text fontSize="lg" fontWeight="bold" my="1%" mx="2%">
                  Score cards:
                </Text>
                <Flex justify="space-between">
                  {scoreCard.map((scoreCard) => (
                    <Box
                      py="6%"
                      w="19%"
                      mx="1%"
                      borderWidth="1px"
                      borderRadius="md"
                      bgColor="gray.300"
                      h="10vh"
                      textAlign="center"
                    >
                      <Text>Score card</Text>
                    </Box>
                  ))}
                </Flex>
                <Flex my="2%" justify="space-between">
                  <Box w="49%">
                    <Text fontSize="lg" fontWeight="bold" my="1%" mx="2%">
                      Graphs:
                    </Text>
                    {graphs.map((graph) => (
                      <Box
                        py="25%"
                        w="100%"
                        my="3%"
                        borderWidth="1px"
                        borderRadius="md"
                        bgColor="gray.300"
                        h="20vh"
                        textAlign="center"
                      >
                        <Text>Graphs</Text>
                      </Box>
                    ))}
                  </Box>
                  <Box w="49%">
                    <Text fontSize="lg" fontWeight="bold" my="1%" mx="2%">
                      Tables:
                    </Text>
                    {tables.map((graph) => (
                      <Box
                        py="25%"
                        w="100%"
                        my="3%"
                        borderWidth="1px"
                        borderRadius="md"
                        bgColor="gray.300"
                        h="20vh"
                        textAlign="center"
                      >
                        <Text>Tables</Text>
                      </Box>
                    ))}
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        </Flex>
        <Flex flexDir="row-reverse">
          <Button
            bgColor="#1b1c1e"
            textColor="#ffffff"
            size="sm"
            w="20%"
            my="2%"
            mr="2%"
            variant="solid"
            onClick={() => setSelectedTemplate(template)}
          >
            Select template
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default TemplateModal;
