import { Box, Button, Flex, Heading, HStack, IconButton, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import { Table } from 'antd'
import axios from 'axios'
import FilterButton from 'components/Button/FilterButton'
import ChakraModal from 'components/Modal/ChakraModal'
import AddConnectionForm from 'components/Modal/Form/AddConnectionForm'
import { BASE_URL } from 'constants/api'
import React, { useEffect } from 'react'
import { FiDatabase } from 'react-icons/fi'
import {GrPowerReset} from "react-icons/gr"

function Databases({columns,database}) {
    const {
        isOpen: addConnectionModalisOpen,
        onOpen: addConnectionModalOnOpen,
        onClose: addConnectionModalOnClose,
    } = useDisclosure();

    const handleAddClick = () => {
        // open modal
    
        addConnectionModalOnOpen();
    };

    
  return (
    <>
        <Flex pt={5} pb={5} borderBottomWidth="1px"  >
            <Box ml="4%" mt="1.5%">
                <FiDatabase  />
            </Box>
            <Text fontSize='xl' fontWeight="bold" align="left" ml="1%" mt="1%">Databases</Text>
            <Spacer />
            <Button size="md" color="white" bgColor="black" w="15%" mr="5%" onClick={handleAddClick}>+ Database</Button>
        </Flex>
        <Box mt="5vh" ml="1%"  w="95%" alignSelf="flex-start" maxW="80vw" mb="3vh" pt="5" pb="5" pl="8" pr="8" border="1px" borderColor="rgba(202, 202, 202, 0.5)">
            <Box>
                <HStack borderBottomWidth='1px' pb="4">
                    <Heading size="sm" color="black" textAlign="left" >
                     {database.length} Database
                    </Heading>
                    <Spacer />
                    <FilterButton />
                    <IconButton icon={<GrPowerReset />} bgColor="#F9FAFC"/>
                </HStack>
                <Box mt="3vh">
                    <Table
                        columns={columns} dataSource={database} size="middle"
                    />
                </Box>
            </Box>
            <ChakraModal
                title="Establish ETL Connection"
                isOpen={addConnectionModalisOpen}
                onOpen={addConnectionModalOnOpen}
                onClose={addConnectionModalOnClose}
                size="2xl"
            >
                <AddConnectionForm
                    onClose={addConnectionModalOnClose}
                />
            </ChakraModal>
        </Box>
    </>
    
  )
}

export default Databases