import React, { useState } from "react";

const TableData = ({ data, headers, tableName, ...rest }) => {
  return (
    <>
      <h1 className="text-2xl text-justify font-bold mb-4">{tableName}</h1>
      <table className="table-auto w-full">
        <thead className="bg-gray-100">
          <tr>
            {headers.map((header) => (
              <th key={header} className="px-4 py-2 text-center font-semibold">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
            >
              {headers.map((header) => (
                <td key={header} className="border px-4 py-2">
                  {entry[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TableData;
