import { Button,Box, Flex, Spacer,Text,Tabs, TabList, TabPanels, Tab, TabPanel, HStack, Heading, IconButton, } from '@chakra-ui/react'
import { Breadcrumb, Table } from 'antd'
import axios from 'axios'
import FilterButton from 'components/Button/FilterButton'
import { BASE_URL } from 'constants/api'
import React, { useEffect, useState } from 'react'
import {GrPowerReset} from "react-icons/gr"
import { useOdinStore } from 'store'
const columns = [
    {
      title: 'Column Name',
      dataIndex: 'column_name',
      render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
          render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
    },
    // {
    //   title: 'Default',
    //   dataIndex: 'default',
    //       render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
  
    // },
    {
      title: 'Null',
      dataIndex: 'null',
          render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
  
    },
  ];

function DBTable({databaseName,tableName}) {

  const [column, setColumn] = useState([])
  const [columnData, setColumnData] = useState([])
  const [data,setData] = useState([])
  const { accessToken } = useOdinStore();

  useEffect(()=>{
      const res = axios.get(`${BASE_URL}/get_single_table_details`,{
          params:{
              dw_name: databaseName,
              table_name:tableName
          },
          headers: {
              Authorization: `Bearer ${accessToken}`,
              "ngrok-skip-browser-warning":"any"
            }
      }).then((response) => {
          let arrays=[]
          console.log("list============>",response.data.table_columns);
          setColumn(response.data.table_columns)
          const arr= response.data.table_columns
          for (let i = 0; i < arr.length; i++) {
            arrays.push({
              title: arr[i].column_name, dataIndex: arr[i].column_name, key: arr[i].column_name
            })
          }
          console.log("Data----------->",arrays)
          setColumnData(arrays)
          
      })
      .catch((error)=> {
          console.log(error);
      });
  },[tableName])


  useEffect(()=>{
    const res = axios.get(`${BASE_URL}/get_all_table_data`,{
        params:{
            dw_name: databaseName,
            table_name:tableName
        },
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning":"any"
          }
    }).then((response) => {
        console.log("list============>",response.data);
        setData(response.data.table_data)
        
    })
    .catch((error)=> {
        console.log(error);
    });
  },[tableName])
  
  return (
    <>
        <Flex  pb={5} pl={5}   >
            <Breadcrumb>
                <Breadcrumb.Item>{databaseName}</Breadcrumb.Item>
                <Breadcrumb.Item>{tableName}</Breadcrumb.Item>
            </Breadcrumb>
            <Text fontSize='xl' fontWeight="bold" align="left" ml="4%" mt="1%"></Text>
        </Flex>
        <Box>
          <Tabs>
            <TabList>
              <Tab>Table Details</Tab>
              <Tab>Columns</Tab>
              <Tab>Views</Tab>
              {/* <Tab>Stages</Tab>
              <Tab>File Format</Tab>
              <Tab>Pipes</Tab>
              <Tab>Stream</Tab>
              <Tab>Tasks</Tab>
              <Tab>Functions</Tab>
              <Tab>Procedures</Tab> */}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box mt="5vh" ml="1%"  w="95%" alignSelf="flex-start" borderRadius='lg' maxW="80vw" mb="3vh" pt="5" pb="5" pl="8" pr="8" border="1px" borderColor="rgba(202, 202, 202, 0.5)" >
                  <Flex>
                    <Spacer />
                    <Button variant="outline" size="sm" mr="1%">Group by role</Button>
                    <Button variant="outline" size="sm">Privilege</Button>
                  </Flex>
                  <Text align="left" fontSize='xl' fontWeight="bold" mt="1%" ml="5%">Privileges</Text>
                  <Flex mt="5%"  ml="5%">
                    <Box>
                      <Text align="left"><b>ACCOUNT ADMIN</b>(current role)</Text>
                    </Box>
                    <Box ml="10%" w="30%">
                      <Button size="sm" variant="outline" mr="2.5%">OWNERSHIP</Button>
                      <Button size="sm" variant="outline">USAGE</Button>
                    </Box>
                  </Flex>
                  <Flex mt="2%" mb="5%" ml="5%">
                    <Box>
                      <Text align="left">PUBLIC</Text>
                    </Box>
                    <Box ml="9%" w="50%">
                      <Button size="sm" variant="outline" >USAGE</Button>
                    </Box>
                  </Flex>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box mt="5vh" ml="1%"  w="95%" alignSelf="flex-start" maxW="80vw" mb="3vh" pt="5" pb="5" pl="8" pr="8" border="1px" borderColor="rgba(202, 202, 202, 0.5)">
                  <Box>
                    <HStack borderBottomWidth='1px' pb="4">
                      <Heading size="sm" color="black" textAlign="left" >
                          {column.length} Columns
                      </Heading>
                      <Spacer />
                      <FilterButton />
                      <IconButton icon={<GrPowerReset />} bgColor="#F9FAFC"/>
                    </HStack>
                    <Box mt="3vh">
                      <Table
                        columns={columns} dataSource={column} size="middle"
                      />
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box mt="5vh" ml="1%"  w="95%" alignSelf="flex-start" maxW="80vw" mb="3vh" pt="5" pb="5" pl="8" pr="8" border="1px" borderColor="rgba(202, 202, 202, 0.5)">
                  <Box>
                    <HStack borderBottomWidth='1px' pb="4">
                      <Heading size="sm" color="black" textAlign="left" >
                          {data.length} Records
                      </Heading>
                      <Spacer />
                      <FilterButton />
                      <IconButton icon={<GrPowerReset />} bgColor="#F9FAFC"/>
                    </HStack>
                    <Box mt="3vh">
                      <Table
                        columns={columnData} dataSource={data} size="middle"
                      />
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
    </>
  )
}

export default DBTable