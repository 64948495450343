import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import fonts from "./fonts";

/**
 * @DESC - Use this file to define colors, font styles, custom variant styles for Buttons and other components that are used from Chakra UI.
 * Check Chakra UI documentation on custom theming for more information
 *
 */

const overrides = {
  colors,
  fonts,
  //   components:
  // Button,
  // Text,
  // Card,
  // Others go here
  //   },
};

export default extendTheme(overrides);
