import React,{ useEffect, useState } from 'react';
import {
    Box,
    Button,
    chakra,
    Center,
    Flex,
    FormErrorMessage,
    Grid,
    Text,
    Input,
    FormLabel,
    Select,
    Stack,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import axios from 'axios';
import { createToast } from 'utils/createToast';
import { notification } from 'antd';
import { BASE_URL } from 'constants/api';
import { useOdinStore } from 'store';



const AddConnectionForm = ({ buttonText, onClose, children, ...props }) => {
    const { accessToken } = useOdinStore()
    const [done, setDone] = useState(false)
    const [source, setSource] = useState(null)
    const [dbs, setDbs] = useState([])
    const [destination, setDestination] = useState(null)
    const {
        handleSubmit,
        register,
        setValue,
        setError,
        clearErrors,
        formState: {errors, isSubmitting}
    } = useForm()


    useEffect(() => {
        console.log("source---------->",accessToken)
        const res = axios.get(`${BASE_URL}/supported_dbs`,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "ngrok-skip-browser-warning":"any"
            },
          }).then((res)=>{
            console.log("Res--------->",res.data)
            setDbs(res.data)
        })
        .catch( (error) => {
            console.log(error);
            
            
          });
    }, [accessToken])

    
    
    const onSubmit = async (body)=>{
        console.log("body=======>",body)
        
        const res = await axios.post(`${BASE_URL}/connect_source`,
        {
            db:source,
            dbname:body.sourceDatabaseName,
            user:body.sourceUser,
            password:body.sourcePassword,
            host:body.sourceHost,
            port:body.port,
            connstr : body.sourceConnectionString,
            data_warehouse_name:body.dataWarehouseName
                    
          },{
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "ngrok-skip-browser-warning":"any"
            },
          },)
          .then((response) =>{
            console.log(response);
            notification.success({
                message: `Connection Established`,
                placement:'bottom',
            });
            onClose()
          })
          .catch( (error) => {
            console.log(error);
            notification.error({
                message: `Unable to create Connection `,
                placement:'bottom',
            });
            
          });
        
    }
    return(
        <>
            <Center>
                <Box d="flex" flexDir="column" {...props} justifyContent="center">
                    <chakra.form onSubmit={handleSubmit(onSubmit)}>
                        <Text mt={10} mb={2}  mr={2} fontSize="lg" fontWeight="900" color="#1b1c1e">
                            Source
                        </Text>
                        <Flex>
                        
                            <Grid
                                templateColumns="repeat(2,1fr)"
                                gap={6}
                                m={6}
                            >
                                <FormLabel>Select Source</FormLabel>
                                <Select placeholder='Select Source' w="100%" onChange={(event)=>setSource(event.target.value) }>
                                    {
                                        dbs.map((db)=>(
                                            <option value={db}>{db}</option>
                                        ))
                                    }
                                    
                                </Select>
                                {
                                    source === "mysql" || source === "psql" ?(
                                        <>
                                            <FormLabel>User</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourceUser", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourceUser && errors.sourceUser?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Password</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="password"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourcePassword", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourcePassword && errors.sourcePassword?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Host</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourceHost", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourceHost && errors.sourceHost?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Port</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("port", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.port && errors.port?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Datebase Name</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourceDatabaseName", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourceDatabaseName && errors.sourceDatabaseName?.message}
                                            </FormErrorMessage> 
                                            <FormLabel>Data Warehouse Name</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("dataWarehouseName", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.dataWarehouseName && errors.dataWarehouseName?.message}
                                            </FormErrorMessage>
                                        </>
                                    ) : source === "mongo"? (
                                        <>
                                            <FormLabel>User</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourceUser", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourceUser && errors.sourceUser?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Password</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="password"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourcePassword", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourcePassword && errors.sourcePassword?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Host</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourceHost", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourceHost && errors.sourceHost?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Port</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("port", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.port && errors.port?.message}
                                            </FormErrorMessage>
                                            
                                            <FormLabel>Datebase Name</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourceDatabaseName", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourceDatabaseName && errors.sourceDatabaseName?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Connection String</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("sourceConnectionString", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.sourceConnectionString && errors.sourceConnectionString?.message}
                                            </FormErrorMessage>
                                            <FormLabel>Data Warehouse Name</FormLabel>
                                            <Input
                                                w="100%"
                                                isRequired
                                                type="text"
                                                focusBorderColor="#1b1c1e"
                                                {...register("dataWarehouseName", {
                                                    required: "This is required",
                                                })}
                                            />
                                            <FormErrorMessage>
                                                {errors.dataWarehouseName && errors.dataWarehouseName?.message}
                                            </FormErrorMessage>
                                        </>
                                    ) : null
                                }
                            </Grid>
                        </Flex>
                        
                        <Flex
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            flexDir="row"
                            pt={5}
                            mb={7}
                            wrap="wrap"
                        >
                            <Button bg="#ffffff" w="20%" size='md' m={2} borderWidth={2} onClick={onClose}><Text color="#1b1c1e">Cancel</Text></Button>
                            <Button 
                                size='md'
                                mr="5%"
                                mt={2}
                                mb={2}
                                ml={2}
                                bg="#1b1c1e"
                                w="20%"
                                type="submit"
                            ><Text color="#ffffff">Save</Text></Button>
                        </Flex>
                    </chakra.form>
                </Box>
            </Center>
        </>
    )
}

export default AddConnectionForm