import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Grid,
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    SimpleGrid,
    Icon,
} from "@chakra-ui/react"
import SearchField from 'components/Others/SearchField'
import {BsThreeDots} from "react-icons/bs"
import DatabaseDetails from './DatabaseDetails'
import DBTable from '../Table'
import Databases from './Databases'
import axios from 'axios'
import {FiDatabase} from "react-icons/fi"
import {BiTable} from "react-icons/bi"
import { useOdinStore } from 'store'
import { BASE_URL } from "../../../../constants/api";


const data = [
    {
        title:"ODIN DATA BASE",
        table:[
            {
                title:"table1",
                columns:[
                    "name",
                    "age",
                    "etc"
                ]
            },
            {
                title:"table1",
            },
            {
                title:"table1",
            },
            {
                title:"table1",
            },
            {
                title:"table1",
            },
        ]
    },
    {
        title:"ISSM DATA BASE",
        table:[
            {
                title:"table1",
            },
            {
                title:"table1",
            },
            {
                title:"table1",
            },
            {
                title:"table1",
            },
            {
                title:"table1",
            },
        ]
    },
]


const columns = [
    {
      title: 'Name',
      dataIndex: 'dbname',
      render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
    },
    {
      title: 'Source',
      dataIndex: 'db',
      
    },
    {
      title: 'owner',
      dataIndex: 'owner',
          render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
  
    },
    {
        title:'Date',
        dataIndex:'date'
    },
    {
      title: 'Created',
      dataIndex: 'time',
      render: (text) => <text >{text.toLocaleString()}</text>,
    },
    
  ];
  const database = [
    {
      key: '1',
      name: 'CFB',
      source:'Local',
      owner:'SYSADMIN',
      created:'3 months ago'
    },
    {
      key: '1',
      name: 'CITI_BIKE',
      source:'Local',
      owner:'DBA_CITIBIKE',
      created:'1 months ago'
    },
    {
      key: '1',
      name: 'CITI_BIKE RESET',
      source:'Local',
      owner:'SYSADMIN',
      created:'3 months ago'
    },
    {
      key: '2',
      name: 'Jim Green',
      source:'Local',
      owner:'DBA_CITIBIKE',
      created:'1 months ago'
    },
    {
      key: '3',
      name: 'Joe Black',
      source:'Local',
      owner:'SYSADMIN',
      created:'3 months ago'
    },
  ];

function NewData() {
    const [status, setStatus] = useState("start")
    const { accessToken } = useOdinStore();
    const [databaseName, setDatabaseName] = useState("")
    const [tableName,setTableName] = useState("")
    const  [databases,setDatabases] = useState([])
    const  [list,setList] = useState([]) 


    useEffect(()=>{
        const res = axios.get(`${BASE_URL}/get_all_db_table_and_columns`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "ngrok-skip-browser-warning":"any"
            }
          })
              .then((response) => {
                console.log("res--------->",response.data)
                  setList(response.data)
              })
              .catch((error)=> {
                  console.log(error);
              });
    },[])

    useEffect(() => {
        const res = axios.get(`${BASE_URL}/api/v1/get_dbs_info_list`, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                "ngrok-skip-browser-warning":"any"
                }
            })
              .then((response) => {
                console.log("databases------->",response.data.databases)
                  setDatabases(response.data.databases)
              })
              .catch((error)=> {
                  console.log(error);
              });
    }, [])
  return (
    <Grid templateColumns="3fr 16fr " gridTemplateRows="auto" w="133%" >
        <Box borderRightWidth="1px" overflowX="hidden" overflowY="auto" h="800px" 
        css={{
            "&::-webkit-scrollbar": {
                width: "4px",
            },
            "&::-webkit-scrollbar-track": {
                width: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#1b1c1e",
                borderRadius: "4px",
            },
            }}
        >
            <Flex mt="20%" mb="10%" p={3}>
                <SearchField w="95%" />
                <IconButton variant='outline' icon={<BsThreeDots />} />
            </Flex>
            <Flex>
                <Box ml="6%" mt="11%">
                    <FiDatabase  />
                </Box>
                <Text align="left" ml="3%" mt="10%" mb="5%" fontWeight='bold'>New Data</Text>
            </Flex>
            {
                list.length !== 0 ? (
                    list.map((data)=>(
                        <Accordion allowToggle >
                            <AccordionItem >
                                <h2>
                                <AccordionButton onClick={()=>{
                                    setStatus("databaseDetails")
                                    setDatabaseName(data.dw_name)
                                } }>
                                    <Flex  textAlign='left'  pt={5} pb={5} pl={1} pr={5} fontSize="11px">
                                        <Text ml="3%">{data.dw_name}</Text>
                                    </Flex>
                                    <AccordionIcon />
                                </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    
                                    {
                                        data.tables.map((table)=>(
                                            <SimpleGrid column={1}>
                                                <Button leftIcon={<BiTable />}  p ={2} fontSize="sm" mb="3%" variant="ghost" onClick={()=>{
                                                    setStatus("table")
                                                    setTableName(table.title)
                                                }}>
                                                    {table.title}
                                                </Button>
                                            </SimpleGrid>
                                            
                                            // <Accordion allowToggle >
                                            //     <AccordionItem >
                                            //         <h2>
                                            //         <AccordionButton onClick={()=>{
                                            //             setStatus("table")
                                            //             setTableName(table.title)
                                            //             }}>
                                            //             <Box flex='1' textAlign='left'  pl={2} fontSize="sm" >
                                            //                 {table.title}
                                            //             </Box>
                                            //             <AccordionIcon />
                                            //         </AccordionButton>
                                            //         </h2>
                                            //         <AccordionPanel pb={4}>
                                            //         </AccordionPanel>
                                            //     </AccordionItem>
                                            // </Accordion>
                                        ))
                                    }
                                    
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    ))
                ) : <Text>Loading</Text>
            }
        </Box>
        <Box mt="4%">
            {
                status === "start" ?(
                    <Databases columns={columns} database={databases} />
                ) : status === "databaseDetails"?(
                    <DatabaseDetails databaseName={databaseName} />
                ) :status === "table"?(
                    <DBTable databaseName={databaseName} tableName={tableName} />
                ) :null
            }
            
        </Box>
    </Grid>
  )
}

export default NewData