import {
    Box,
    chakra,
    IconButton,
    LinkBox,
    LinkOverlay,
  } from '@chakra-ui/react';
  import React from 'react';
  import { BsEnvelopeFill } from 'react-icons/bs';
import { IoIosNotifications } from 'react-icons/io';
  
const NotificationButton = ({ ...props }) => {
  
    return (
      <>
        <LinkBox>
          <LinkOverlay >
            {/* <Link to={'/vendor-home/messages'}> */}
            <Box
              // as="button"
              boxShadow="xl"
              borderRadius="12px"
              m={2}
            >
              <IconButton
                variant="previewIcon"
                aria-label="Preview"
                fontSize="22px"
                icon={
                  <>
                    <IoIosNotifications />
                  </>
                }
              />
            </Box>
            {/* </Link> */}
          </LinkOverlay>
        </LinkBox>
      </>
    );
  };
  
  export default NotificationButton;
  