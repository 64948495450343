import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NewData from "./Data/NewData";
import Scheduler from "./Scheduler";
const { Grid } = require("@chakra-ui/react");
const { Sidebar } = require("components/Sidebar");


const Odin = () => {
  return (
  <>
  
    <Grid templateColumns="1fr 9fr 3fr" gridTemplateRows="auto">
      <Sidebar />
        <Routes>
          <Route path="/new-data" element={<NewData />} />
          <Route path="/data-scheduler" element={<Scheduler />} />
        </Routes>
    </Grid>
  </>
          
  );
};

export default Odin;
