import React, { useState } from "react";
import { Button } from "@chakra-ui/react";
import CodeEditor from "@uiw/react-textarea-code-editor";

const SqlEditor = ({
  visualizerIsVisible,
  setVisualizerIsVisible,
  queryText,
  setQueryText,
}) => {
  return (
    <>
      <CodeEditor
        value={queryText}
        language="sql"
        placeholder="Please enter SQL code."
        onChange={(evn) => setQueryText(evn.target.value)}
        padding={15}
        style={{
          fontSize: 12,
          overflowY: "auto",
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
      />
    </>
  );
};

export default SqlEditor;
