import {Box, Flex,Text,Tabs, TabList, TabPanels, Tab, TabPanel, Button, Spacer, HStack, Heading, IconButton } from '@chakra-ui/react'
import { Table } from 'antd'
import axios from 'axios'
import FilterButton from 'components/Button/FilterButton'
import { BASE_URL } from 'constants/api'
import React, { useEffect, useState } from 'react'
import { FiDatabase } from 'react-icons/fi'
import {GrPowerReset} from "react-icons/gr"
import { useOdinStore } from 'store'
const columns = [
    {
      title: 'Table Name',
      dataIndex: 'table_name',
      render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
    },
    {
      title: 'Rows',
      dataIndex: 'no_of_rows',
          render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
  
    },
    {
        title: 'owner',
        dataIndex: 'owner',
            render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
    
    },
    {
        title: 'Size',
        dataIndex: 'size',
            render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
    
      },
    {
        title: 'Type',
        dataIndex: 'type',
            render: (text) => <text style={{textTransform:'uppercase'}}>{text}</text>,
    
    },
    {
      title: 'Created Time',
      dataIndex: 'time',
    },
    
  ];
function DatabaseDetails({databaseName}) {
    const { accessToken } = useOdinStore();

    const [table, setTable] = useState([])
    useEffect(()=>{
        const res = axios.get(`${BASE_URL}/get_db_table_details`,{
            params:{
                dw_name: databaseName,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "ngrok-skip-browser-warning":"any"
              }
        }).then((response) => {
            console.log("list============>",response.data.database_details);
            setTable(response.data.database_details)
            
        })
        .catch((error)=> {
            console.log(error);
        });
    },[databaseName])
  return (
    <>
        <Flex pb={5}  >
            <Box ml="2%" mt="1.75%">
                <FiDatabase  />
            </Box>
            <Text fontSize='xl' fontWeight="bold" align="left" ml="1%" mt="1%">{databaseName}</Text>
        </Flex>
        <Box>
            <Tabs>
                <TabList>
                    <Tab>Database Details</Tab>
                    <Tab>Tables</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Box mt="5vh" ml="1%"  w="95%" alignSelf="flex-start" borderRadius='lg' maxW="80vw" mb="3vh" pt="5" pb="5" pl="8" pr="8" border="1px" borderColor="rgba(202, 202, 202, 0.5)" >
                            <Text align="left" fontSize='xl' fontWeight="bold" mt="3%" ml="5%">Source Details</Text>
                            <Flex mt="5%" mb="5%" ml="5%">
                                <Box>
                                    <Text align="left">Share</Text>
                                    <Text align="left">SFC_SAMPLES_AWS_AP SOUTH1 1.S...</Text>
                                </Box>
                                <Box ml="10%">
                                    <Text align="left">Shared by</Text>
                                    <Text align="left">SFSALESSHARE</Text>
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt="5vh" ml="1%"  w="95%" alignSelf="flex-start" borderRadius='lg' maxW="80vw" mb="3vh" pt="5" pb="5" pl="8" pr="8" border="1px" borderColor="rgba(202, 202, 202, 0.5)" >
                            <Flex>
                                <Spacer />
                                <Button variant="outline" size="sm" mr="1%">Group by role</Button>
                                <Button variant="outline" size="sm">Privilege</Button>
                            </Flex>
                            <Text align="left" fontSize='xl' fontWeight="bold" mt="1%" ml="5%">Privileges</Text>
                            <Flex mt="5%"  ml="5%">
                                <Box>
                                    <Text align="left"><b>ACCOUNT ADMIN</b>(current role)</Text>
                                </Box>
                                <Box ml="10%" w="30%">
                                    <Button size="sm" variant="outline" mr="2.5%">OWNERSHIP</Button>
                                    <Button size="sm" variant="outline">USAGE</Button>
                                </Box>
                            </Flex>
                            <Flex mt="2%" mb="5%" ml="5%">
                                <Box>
                                    <Text align="left">PUBLIC</Text>
                                </Box>
                                <Box ml="9%" w="50%">
                                    <Button size="sm" variant="outline" >USAGE</Button>
                                </Box>
                            </Flex>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box mt="5vh" ml="1%"  w="95%" alignSelf="flex-start" maxW="80vw" mb="3vh" pt="5" pb="5" pl="8" pr="8" border="1px" borderColor="rgba(202, 202, 202, 0.5)">
                            <Box>
                                <HStack borderBottomWidth='1px' pb="4">
                                    <Heading size="sm" color="black" textAlign="left" >
                                        {table.length} Tables
                                    </Heading>
                                    <Spacer />
                                    <FilterButton />
                                    <IconButton icon={<GrPowerReset />} bgColor="#F9FAFC"/>
                                </HStack>
                                <Box mt="3vh">
                                    <Table
                                        columns={columns} dataSource={table} size="middle"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    </>
  )
}

export default DatabaseDetails