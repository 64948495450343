import { ChakraProvider, Grid } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import theme from "../../assets/theme";
import PrivateApp from "../../pages/private";
import "./App.css";
import "antd/dist/antd.min.css";
import { Sidebar } from "components/Sidebar";
import NewData from "pages/private/Data/NewData/index.js";
import Scheduler from "pages/private/Scheduler";
import Login from "pages/private/Data/Login";

import Odin from "../../pages/private";
import { useOdinStore } from "store";
import Dashboard from "pages/public/Landing";
import BusinessExplorer from "pages/private/BusinessExplorer";
import TopRightSection from "components/Others/TopRightSection";
import NewBusinessExplorer from "pages/private/BusinessExplorer/newBusinessExplorer";
import BusinessDashboard from "pages/private/BusinessExplorer/BusinessDashboard";

function App() {
  const { isLoggedIn } = useOdinStore();
  return (
    <ChakraProvider theme={theme} resetCSS>
      <Router>
        {isLoggedIn === false ? (
          <div className="App">
            <Routes>
              <Route path="/" element={<Login />} />
            </Routes>
          </div>
        ) : (
          <div className="App">
            <Grid templateColumns="1fr 9fr 3fr" gridTemplateRows="auto">
              <Sidebar />
              <TopRightSection />
              <Routes>
                <Route path="/new-data" element={<NewData />} />
                <Route path="/data-scheduler" element={<Scheduler />} />
                <Route
                  path="/business-explorer"
                  element={<BusinessDashboard />}
                />
                {/* <Route
                  path="/"
                  element={<BusinessDashboard />}
                /> */}
              </Routes>
            </Grid>
          </div>
        )}
      </Router>
    </ChakraProvider>
  );
}

export default App;
