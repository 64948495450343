import { BiHomeAlt } from "react-icons/bi";
import { BsCameraVideo, BsPhone } from "react-icons/bs";
import { TbBrandGoogleAnalytics } from "react-icons/tb";

export const sidebarData = [
  {
    title: "Data",
    icon: BiHomeAlt,
    submenu: [
      {
        title: "New Data",
        path: "/new-data",
      },
      {
        title: "Transform Sheets",
        path: "/",
      },
      {
        title: "External Sources",
        path: "/",
      },
    ],
  },
  {
    title: "Data Pipeline",
    icon: BsCameraVideo,
    submenu: [
      {
        title: "Build Data Pipelines",
        path: "/",
      },
      // {
      //   title: "Scheduler",
      //   path: "/data-scheduler",
      // },
      {
        title: "Data Health",
        path: "/",
      },
    ],
  },
  {
    title: "Work Sheets",
    icon: BsPhone,
    submenu: [
      {
        title: "Work Sheets",
        path: "/data-scheduler",
      },
    ],
  },
  // {
  //   title: "Data Lineage",
  //   icon: BsPhone,
  //   submenu: [
  //     {
  //       title: "Data Lineage",
  //       path: "/data-lineage",
  //     },
  //   ],
  // },
  {
    title: "Business Explorer",
    icon: TbBrandGoogleAnalytics,
    submenu: [
      {
        title: "Business Explorer",
        path: "/business-explorer",
      },
    ],
  },
];
