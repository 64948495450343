import { Box, Flex, Image, Stack, Icon as ChakraIcon } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { FaSignOutAlt } from "react-icons/fa";

import { Link, useLocation } from "react-router-dom";
import { useOdinStore } from "store";
import odin from "../../assets/odin.jpg";
import { sidebarData } from "./sidebar.data";
const { SubMenu } = Menu;

export const Sidebar = () => {
  // useMemo. Prevent Re-render on Refresh

  const [selected, setSelected] = useState(null);
  const [openKeys, setOpenKeys] = React.useState([]);
  const [filteredSideItems, setFilteredSideItems] = useState([]);

  const { setAccessToken,setIsLoggedIn } = useOdinStore();
  
  const location = useLocation();
  
  // const navigate = useNavigate(); // Replaced useHistory
  useEffect(() => {
    setFilteredSideItems(sidebarData);
  }, [sidebarData]);

  useEffect(() => {
    const splitPathname = location.pathname.split("/");

    filteredSideItems.forEach((val, idx) => {
      if (val.path === `/${splitPathname[1]}`) {
        setSelected(idx);
        return;
      }
    });
  }, [location]);

  const onOpenChange = (keys) => {
    // console.log(keys);
    // console.log("open keys--->", openKeys);
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    // console.log("last open keys--->", latestOpenKey);
    if (filteredSideItems.indexOf(latestOpenKey) === -1) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      //setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      // console.log("last open keys--->", latestOpenKey);
    }
  };

  const handleLogOut = () =>{
    setAccessToken(null)
    setIsLoggedIn(false)
  }

  return (
    <Flex
      height="100vh"
      width={{ sm: "250px" }}
      dir="column"
      borderRightWidth="1px"
      py={8}
      pos="sticky"
      top={0}
      left={0}
      zIndex={2}
      //boxShadow="2xl"
      flexShrink={0}
      justify="center"
      overflow="hidden"
    >
      {/* @TODO: Avatar */}
      <Stack w="100%" justify="space-between">
      <Image
          borderWidth="thick"
          borderColor="black"
          justifyItems="center"
          alignSelf="center"
          w="50%"
        
          src={odin}
        />
        <Box overflow="scroll" overflowX="hidden" overflowY="hidden" ml="10%">
          {filteredSideItems.map((item, idx) => (
            <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
              <SubMenu
                key={idx}
                icon={<Icon icon={item.icon} />}
                title={item.title}
              >
                {item.submenu.map((submenu, subIdx) => (
                  <Menu.Item key={subIdx}>
                    <Link to={submenu.path}>
                      <p style={{ color: "#fffff" }}>{submenu.title}</p>
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            </Menu>
          ))}
        </Box>
        <Link onClick={handleLogOut} to="">
          <ChakraIcon
            // mt={50}
            // pos="absolute"
            // bottom={10}
            alignSelf="center"
            mr={3}
            pointerEvents="auto"
            as={FaSignOutAlt}
            boxSize="30px"
            color="#A0A8B9"
            transition="ease 0.3s"
            _hover={{ color: "black" }}
          />
        </Link>
      </Stack>
    </Flex>
  );
};
