import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Text,
  Button,
  useDisclosure,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  FormLabel,
  Input,
  Spacer,
  Switch,
  Center,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  SimpleGrid,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ChakraModal from "components/Modal/ChakraModal";
import CreateWidget from "components/Modal/Form/Widget/createWidget";
import { Select } from "antd";
import { useOdinStore } from "store";
import axios from "axios";
import { BASE_URL } from "constants/api";
import { useGraphStore } from "graphStore";
import Chart from "components/Chart";
import TableData from "./components/tableData";
import StatCard from "./components/statCard";
import TemplateModal from "./components/templateModal";
const { Option } = Select;
const BusinessDashboard = () => {
  const [color, setColor] = useState("gray.500");
  const { accessToken } = useOdinStore();
  const {
    setGraphType,
    setXValue,
    setYValue,
    graphType,
    xValue,
    yValue,
    setResults,
    setOperator,
    operator,
    results,
  } = useGraphStore();
  const [databases, setDatabases] = useState([]);
  const [databaseName, setDatabaseName] = useState(null);
  const [tableName, setTableName] = useState(null);
  const [tables, setTables] = useState([]);
  const [column, setColumn] = useState([]);
  const [arithmaticOperations, setArithmaticOperations] = useState([]);
  const [xAxis, setXAxis] = useState(null);
  const [yAxis, setYAxis] = useState(null);
  const [yAxisArray, setYAxisArray] = useState(null);
  const [xAxisValue, setXAxisValue] = useState(null);
  const [yAxisValue, setYAxisValue] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [graphs, setGraphs] = useState([]);
  const [result, setResult] = useState(null);
  const [colName, setColName] = useState(null);
  const [rangeOperatorX, setRangeOperatorX] = useState(null);
  const [rangeOperatorY, setRangeOperatorY] = useState(null);
  const [comparisonValueX, setComparisonValueX] = useState(null);
  const [comparisonValueY, setComparisonValueY] = useState(null);
  const [rangeColY, setRangeColY] = useState(null);
  const [buttonStatus, setButtonStatus] = useState("save");
  const [graphId, setGraphId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState(null);
  const [table, setTable] = useState([]);
  const [dashboardTableName, setDashbaordTableName] = useState(null);
  const [primaryColumn, setPrimaryColumn] = useState(null);
  const [primaryOperator, setPrimaryOperator] = useState(null);
  const [secondaryColumn, setSecondaryColumn] = useState(null);
  const [secondaryOperator, setSecondaryOperator] = useState(null);
  const [primaryValue, setPrimaryValue] = useState(null);
  const [secondaryValue, setSecondaryValue] = useState(null);
  const [scoreCardTitle, setScoreCardTitle] = useState(null);
  const [scoreCard, setScorecard] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("template1");

  const colors = [
    "gray.500",
    "red.500",
    "gray.700",
    "green.500",
    "blue.500",
    "blue.800",
    "yellow.500",
    "orange.500",
    "purple.500",
    "pink.500",
  ];

  const {
    isOpen: addWidgetModalisOpen,
    onOpen: addWidgetModalOnOpen,
    onClose: addWidgetModalOnClose,
  } = useDisclosure();

  const {
    isOpen: addTemplateModalisOpen,
    onOpen: addTemplateModalOnOpen,
    onClose: addTemplateModalOnClose,
  } = useDisclosure();

  const handleAddClick = () => {
    // open modal
    addWidgetModalOnOpen();
  };

  const handleTemplateModal = () => {
    addTemplateModalOnOpen();
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    setGraphType(null);
    setXValue(null);
    setYValue(null);
    setXAxisValue(null);
    setYAxisValue(null);
  }, []);

  useEffect(() => {
    const res = axios
      .get(`${BASE_URL}/db_list`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((response) => {
        setDatabases(response.data.databases);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (databaseName !== null) {
      const res = axios
        .get(`${BASE_URL}/get_db_table_details`, {
          params: {
            dw_name: databaseName,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning": "any",
          },
        })
        .then((response) => {
          setTables(response.data.database_details);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [databaseName]);

  useEffect(() => {
    if (tableName !== null) {
      const res = axios
        .get(`${BASE_URL}/get_single_table_details`, {
          params: {
            dw_name: databaseName,
            table_name: tableName,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "ngrok-skip-browser-warning": "any",
          },
        })
        .then((response) => {
          setColumn(response.data.table_columns);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tableName]);

  useEffect(() => {
    const res = axios
      .get(`${BASE_URL}/supported_arithmatic_operations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning": "any",
        },
      })
      .then((response) => {
        setArithmaticOperations(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleGraph = () => {
    setGraphs((graphs) => [
      ...graphs,
      {
        graphType: graphType,
        xValue: xAxisValue,
        yValue: yAxisValue,
      },
    ]);

    // setXAxisValue(null);
    // setYAxisValue(null);
  };

  const handleSaveTable = () => {
    setTable((table) => [
      ...table,
      {
        tableName: dashboardTableName,
        headers: headers,
        tableData: tableData,
      },
    ]);

    // setXAxisValue(null);
    // setYAxisValue(null);
  };

  const handleSaveScorecard = () => {
    setScorecard((scorecard) => [
      ...scorecard,
      {
        scoreCardTitle: scoreCardTitle,
        primaryValue: primaryValue,
        secondaryValue: secondaryValue,
      },
    ]);

    // setXAxisValue(null);
    // setYAxisValue(null);
  };

  useEffect(() => {
    if (yAxis !== null) {
      axios
        .post(
          `${BASE_URL}/graph_operation`,
          {
            dw_name: databaseName,
            table_name: tableName,
            column_name_x: xAxis,
            column_name_y: yAxis,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const yAxis = response.data.result.Y;
          const xAxis = response.data.result.X;
          setYAxisArray(yAxis);
          let yData = [];
          let xData = [];
          yAxis.map((y) => {
            yData.push({
              data: y.column_data,
            });
          });
          xAxis.map((x) => {
            xData.push(x.column_data);
          });
          setXAxisValue(xData);
          setYAxisValue(yData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [xAxis, yAxis]);

  useEffect(() => {
    if (xAxis !== null) {
      axios
        .post(
          `${BASE_URL}/get_column_data`,
          {
            data_warehouse: databaseName,
            table_name: tableName,
            column_name: [xAxis],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          const column = response.data.column_data;
          let data = [];
          const result = column.map((col) => {
            data.push(col.value);
          });
          setXAxisValue(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [databaseName, tableName, xAxis]);

  const handleDatabase = (value) => {
    setDatabaseName(value);
  };

  const handleTable = (value) => {
    setTableName(value);
  };

  const handleColumnX = (value) => {
    setXAxis(value);
  };

  const handlePrimaryValue = (value) => {
    setPrimaryColumn(value);
  };

  const handlePrimaryOperator = (value) => {
    setPrimaryOperator(value);
  };

  const handleSecondaryValue = (value) => {
    setSecondaryColumn(value);
  };
  const handleSecondaryOperator = (value) => {
    setSecondaryOperator(value);
  };

  const handleColumnY = (value) => {
    console.log("y--------->", value);
    setYAxis(value);
    setColName(value[0]);
  };

  const handleTableColumns = async (value) => {
    console.log("y--------->", value);
    const res = await axios
      .post(
        `${BASE_URL}/get_column_data`,
        {
          data_warehouse: databaseName,
          table_name: tableName,
          column_name: value,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log("column data------->", response.data);
        const column = response.data.column_data;
        setTableData(column);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("table data ------------->", tableData);
    if (tableData.length > 0) {
      setHeaders(Object.keys(tableData[0]));
    }
  }, [tableData]);

  const handleOperator = (value) => {
    setSelectedOperator(value);
  };

  const handleChangeRangeX = (value) => {
    setRangeOperatorX(value);
  };

  const handleChangeRangeY = (value) => {
    setRangeOperatorY(value);
  };

  const handleColumnRangeY = (value) => {
    axios
      .post(
        `${BASE_URL}/get_single_y_axis`,
        {
          data_warehouse: databaseName,
          table_name: tableName,
          column_name: value,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setRangeColY(response.data.column_data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (comparisonValueY !== null) {
      axios
        .post(
          `${BASE_URL}/range_result`,
          {
            col_list: rangeColY.column_data,
            comparison_type: rangeOperatorY,
            comparison_value: comparisonValueY,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "ngrok-skip-browser-warning": "any",
            },
          }
        )
        .then((response) => {
          console.log(response.data.result);
          yAxisArray.forEach((column, index) => {
            if (column.column_name === rangeColY.column_name) {
              yAxisArray[index].column_data = response.data.result;
            }
          });
          let yData = [];
          console.log("yAxisArray", yAxisArray);
          yAxisArray.map((y) => {
            yData.push({
              data: y.column_data,
            });
          });
          setYAxisValue(yData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [comparisonValueY]);

  useEffect(() => {
    console.log("graphs---------->", graphs);
    console.log("y------->", yValue);
  }, [graphs, yValue]);

  const handleSelectGraph = (id) => {
    setButtonStatus("edit");
    setGraphId(id);
    const findGraph = { ...graphs[id] };
    console.log("findGraph", findGraph);
    setGraphType(findGraph.graphType);
    setXAxisValue(findGraph.xValue);
    setYAxisValue(findGraph.yValue);
  };

  const handleEditGraph = () => {
    const newObj = {
      graphType: graphType,
      xValue: xAxisValue,
      yValue: yAxisValue,
    };

    graphs[graphId] = newObj;
    setButtonStatus("save");
  };

  useEffect(() => {
    if (primaryOperator !== null && primaryColumn !== null) {
      const res = axios
        .post(
          `${BASE_URL}/arithmatic_operation`,
          {
            dw_name: databaseName,
            table_name: tableName,
            column_name: [primaryColumn],
            arithmatic_operation: primaryOperator,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "ngrok-skip-browser-warning": "any",
            },
          }
        )
        .then((response) => {
          console.log(
            "primary arithemeatic operation------------>",
            response.data.result[0].value
          );
          setPrimaryValue(response.data.result[0].value);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [primaryColumn, primaryOperator]);

  useEffect(() => {
    if (secondaryOperator !== null && secondaryColumn !== null) {
      const res = axios
        .post(
          `${BASE_URL}/arithmatic_operation`,
          {
            dw_name: databaseName,
            table_name: tableName,
            column_name: [secondaryColumn],
            arithmatic_operation: secondaryOperator,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "ngrok-skip-browser-warning": "any",
            },
          }
        )
        .then((response) => {
          console.log(
            "secondary arithemeatic operation------------>",
            response.data.result[0].value
          );
          setSecondaryValue(response.data.result[0].value);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [secondaryColumn, secondaryOperator]);

  return (
    <Flex w="133%" mt="6%">
      <Box w="80%" bgColor="#F7F7F7" borderWidth="1px" borderColor="#E8E8E8">
        {graphType === null ? (
          <Box
            my="20%"
            mx="30%"
            p={8}
            bgColor="#FCFCFC"
            borderWidth="1px"
            borderColor="#E8E8E8"
          >
            <Heading fontSize="md">Create a Widget</Heading>
            <Text fontSize="12px" color="#737475" my="4%">
              Create a widget for your object
            </Text>
            <Button
              leftIcon={<AddIcon />}
              bgColor="#1b1c1e"
              textColor="#ffffff"
              size="sm"
              w="70%"
              my="4%"
              variant="solid"
              onClick={handleAddClick}
            >
              Add Widget
            </Button>
          </Box>
        ) : (
          <Box pt="10">
            <Flex flexDir="row-reverse">
              <Button
                leftIcon={<AddIcon />}
                bgColor="#1b1c1e"
                textColor="#ffffff"
                size="sm"
                w="20%"
                my="2%"
                mr="2%"
                variant="solid"
                onClick={handleAddClick}
              >
                Add Widget
              </Button>
            </Flex>
            <Box mb="4%">
              <Box w="85%" my="2%" pl="14%">
                {graphType === "table" ? (
                  <Box h="40vh" overflowY="auto">
                    {headers !== null && (
                      <TableData
                        data={tableData}
                        headers={headers}
                        tableName={dashboardTableName}
                      />
                    )}
                  </Box>
                ) : graphType === "scorecard" ? (
                  <Box w="40%" ml="30%">
                    <StatCard
                      primaryValue={primaryValue}
                      secondaryValue={secondaryValue}
                      scoreCardTitle={scoreCardTitle}
                    />
                  </Box>
                ) : (
                  <Chart type={graphType} x={xAxisValue} y={yAxisValue} />
                )}
              </Box>
              <Divider mx="2%" w="95%" />
              <Flex pt="1%" px="3%" textAlign="justify" flexDir="row-reverse">
                <Button
                  leftIcon={<AddIcon />}
                  bgColor="#1b1c1e"
                  textColor="#ffffff"
                  size="sm"
                  w="20%"
                  my="2%"
                  mr="2%"
                  variant="solid"
                  onClick={handleTemplateModal}
                >
                  Select Template
                </Button>
              </Flex>
              {selectedTemplate === "template1" ? (
                <Box bgColor="white" pb="2%" mt="3%" mx="2%">
                  <Box py="2%" px="3%" textAlign="justify">
                    <Text>Preview</Text>
                  </Box>

                  {scoreCard.length > 0 && (
                    <SimpleGrid columns={5} spacing={5} bgColor="white" px="3%">
                      {scoreCard.map((scoreCard, idx) => (
                        <>
                          <Box bgColor="white">
                            <StatCard
                              primaryValue={scoreCard.primaryValue}
                              secondaryValue={scoreCard.secondaryValue}
                              scoreCardTitle={scoreCard.scoreCardTitle}
                            />
                          </Box>
                        </>
                      ))}
                    </SimpleGrid>
                  )}

                  <Box my="2%">
                    {graphs.length > 0 && (
                      <SimpleGrid
                        columns={2}
                        spacing={5}
                        bgColor="white"
                        px="3%"
                      >
                        {graphs.map((graph, idx) => (
                          <>
                            <Box
                              bgColor="white"
                              p="2%"
                              borderWidth="1px"
                              onClick={() => handleSelectGraph(idx)}
                            >
                              <Chart
                                type={graph.graphType}
                                x={graph.xValue}
                                y={graph.yValue}
                              />
                            </Box>
                          </>
                        ))}
                      </SimpleGrid>
                    )}
                  </Box>
                  {table.length > 0 && (
                    <SimpleGrid columns={2} spacing={5} bgColor="white" px="3%">
                      {table.map((table, idx) => (
                        <>
                          <Box
                            bgColor="white"
                            p="2%"
                            h="30vh"
                            borderWidth="1px"
                            overflowX="auto"
                            overflowY="auto"
                          >
                            <TableData
                              data={table.tableData}
                              headers={table.headers}
                              tableName={table.dashboardTableName}
                            />
                          </Box>
                        </>
                      ))}
                    </SimpleGrid>
                  )}
                </Box>
              ) : (
                <Box bgColor="white" pb="2%" mt="3%" mx="2%">
                  <Box py="2%" px="3%" textAlign="justify">
                    <Text>Preview</Text>
                  </Box>

                  {scoreCard.length > 0 && (
                    <SimpleGrid columns={5} spacing={5} bgColor="white" px="3%">
                      {scoreCard.map((scoreCard, idx) => (
                        <>
                          <Box bgColor="white">
                            <StatCard
                              primaryValue={scoreCard.primaryValue}
                              secondaryValue={scoreCard.secondaryValue}
                              scoreCardTitle={scoreCard.scoreCardTitle}
                            />
                          </Box>
                        </>
                      ))}
                    </SimpleGrid>
                  )}

                  <Flex my="2%">
                    <Box w="49%">
                      {graphs.length > 0 && (
                        <SimpleGrid
                          columns={1}
                          spacing={5}
                          bgColor="white"
                          px="3%"
                        >
                          {graphs.map((graph, idx) => (
                            <>
                              <Box
                                bgColor="white"
                                p="2%"
                                borderWidth="1px"
                                onClick={() => handleSelectGraph(idx)}
                              >
                                <Chart
                                  type={graph.graphType}
                                  x={graph.xValue}
                                  y={graph.yValue}
                                />
                              </Box>
                            </>
                          ))}
                        </SimpleGrid>
                      )}
                    </Box>
                    <Box w="49%">
                      {table.length > 0 && (
                        <SimpleGrid
                          columns={1}
                          spacing={5}
                          bgColor="white"
                          px="3%"
                        >
                          {table.map((table, idx) => (
                            <>
                              <Box
                                bgColor="white"
                                p="2%"
                                h="44vh"
                                borderWidth="1px"
                                overflowX="auto"
                                overflowY="auto"
                              >
                                <TableData
                                  data={table.tableData}
                                  headers={table.headers}
                                  tableName={table.dashboardTableName}
                                />
                              </Box>
                            </>
                          ))}
                        </SimpleGrid>
                      )}
                    </Box>
                  </Flex>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {graphType !== null ? (
        <Box w="30%">
          {graphType === "table" ? (
            <Text ml="4%" my="4%" fontSize="md" align="left">
              Table
            </Text>
          ) : (
            <Text ml="4%" my="4%" fontSize="md" align="left">
              {graphType} Graph
            </Text>
          )}
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton bgColor="#F7F7F7">
                  <Box flex="1" textAlign="left">
                    Data
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text align="left" fontSize="md" fontWeight="bold">
                  Database
                </Text>
                <Flex my="4%" w="100%">
                  <Select
                    variant="filled"
                    style={{
                      width: "100%",
                      marginTop: "2%",
                    }}
                    placeholder="Select Database"
                    onChange={handleDatabase}
                  >
                    {databases.map((database) => (
                      <Option value={database} label={database}>
                        <div className="demo-option-label-item">
                          <span role="img" aria-label="TMS">
                            {database}
                          </span>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Flex>
                <Box my="4%" w="100%">
                  <Text align="left" fontWeight="semibold">
                    Data set
                  </Text>
                  <Select
                    placeholder="Select Table Name"
                    allowClear
                    style={{
                      width: "100%",
                      marginTop: "2%",
                    }}
                    onChange={handleTable}
                  >
                    {tables.map((table) => (
                      <Option value={table.table_name} label={table.table_name}>
                        <div className="demo-option-label-item">
                          <span role="img" aria-label="TMS">
                            {table.table_name}
                          </span>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Box>
                {graphType === "table" ? (
                  <Box my="2%">
                    <Text align="left" fontWeight="normal">
                      Select Columns
                    </Text>
                    <Select
                      placeholder="Select Data set"
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                        marginRight: "20%",
                        marginTop: "2%",
                      }}
                      onChange={handleTableColumns}
                    >
                      {column.map((column) => (
                        <Option
                          value={column.column_name}
                          label={column.column_name}
                        >
                          <div className="demo-option-label-item">
                            <span role="img" aria-label="TMS">
                              {column.column_name}
                            </span>
                          </div>
                        </Option>
                      ))}
                    </Select>
                    <Flex mt="5%" flexDir="row-reverse">
                      <Button
                        bgColor="#1b1c1e"
                        textColor="white"
                        onClick={handleSaveTable}
                      >
                        Save Table
                      </Button>
                    </Flex>
                  </Box>
                ) : graphType === "scorecard" ? (
                  <Box my="2%">
                    <Text align="left" fontWeight="normal">
                      Select Columns
                    </Text>
                    <Flex justify="space-between" my="3%">
                      <Box w="45%">
                        <Text textAlign="justify">Primary Value</Text>
                        <Select
                          placeholder="Select Data set"
                          allowClear
                          style={{
                            width: "100%",
                            marginRight: "20%",
                            marginTop: "2%",
                          }}
                          onChange={handlePrimaryValue}
                        >
                          {column.map((column) => (
                            <Option
                              value={column.column_name}
                              label={column.column_name}
                            >
                              <div className="demo-option-label-item">
                                <span role="img" aria-label="TMS">
                                  {column.column_name}
                                </span>
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Box>
                      <Box w="45%">
                        <Text align="left" fontWeight="normal">
                          Operation
                        </Text>
                        <Select
                          style={{
                            width: "100%",
                            marginTop: "2%",
                          }}
                          onChange={handlePrimaryOperator}
                        >
                          {arithmaticOperations.map((operation) => (
                            <Option value={operation} label={operation}>
                              <div className="demo-option-label-item">
                                <span role="img" aria-label="TMS">
                                  {operation}
                                </span>
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Box>
                    </Flex>
                    <Flex justify="space-between" my="3%">
                      <Box w="45%">
                        <Text textAlign="justify">Secondary Value</Text>
                        <Select
                          placeholder="Select Data set"
                          allowClear
                          style={{
                            width: "100%",
                            marginRight: "20%",
                            marginTop: "2%",
                          }}
                          onChange={handleSecondaryValue}
                        >
                          {column.map((column) => (
                            <Option
                              value={column.column_name}
                              label={column.column_name}
                            >
                              <div className="demo-option-label-item">
                                <span role="img" aria-label="TMS">
                                  {column.column_name}
                                </span>
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Box>
                      <Box w="45%">
                        <Text align="left" fontWeight="normal">
                          Operation
                        </Text>
                        <Select
                          style={{
                            width: "100%",
                            marginTop: "2%",
                          }}
                          onChange={handleSecondaryOperator}
                        >
                          {arithmaticOperations.map((operation) => (
                            <Option value={operation} label={operation}>
                              <div className="demo-option-label-item">
                                <span role="img" aria-label="TMS">
                                  {operation}
                                </span>
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Box>
                    </Flex>

                    <Box textAlign="left">
                      <FormLabel textAlign="left">Score Card Name</FormLabel>
                      <Input
                        size="md"
                        placeholder="Enter Score Card Name"
                        onChange={(e) => setScoreCardTitle(e.target.value)}
                      />
                    </Box>

                    <Flex mt="5%" flexDir="row-reverse">
                      <Button
                        bgColor="#1b1c1e"
                        textColor="white"
                        onClick={handleSaveScorecard}
                      >
                        Save Scorecard
                      </Button>
                    </Flex>
                  </Box>
                ) : (
                  <>
                    <Box my="2%">
                      <Text align="left" fontWeight="normal">
                        X-axis / Group By
                      </Text>
                      <Select
                        placeholder="Select Data set"
                        style={{
                          width: "100%",
                          marginRight: "20%",
                          marginTop: "2%",
                        }}
                        onChange={handleColumnX}
                      >
                        {column.map((column) => (
                          <Option
                            value={column.column_name}
                            label={column.column_name}
                          >
                            <div className="demo-option-label-item">
                              <span role="img" aria-label="TMS">
                                {column.column_name}
                              </span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Box>
                    <Box my="2%">
                      <Text align="left" fontWeight="normal">
                        Y-axis
                      </Text>
                      <Select
                        placeholder="Select Data set"
                        mode="multiple"
                        allowClear
                        style={{
                          width: "100%",
                          marginRight: "20%",
                          marginTop: "2%",
                        }}
                        onChange={handleColumnY}
                      >
                        {column.map((column) => (
                          <Option
                            value={column.column_name}
                            label={column.column_name}
                          >
                            <div className="demo-option-label-item">
                              <span role="img" aria-label="TMS">
                                {column.column_name}
                              </span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Box>
                  </>
                )}

                <Box my="2%">
                  {graphType !== "table" && graphType !== "scorecard" ? (
                    <>
                      <Text align="left" fontWeight="normal">
                        Define Process for X axis
                      </Text>
                      <Box>
                        <Flex w="120%">
                          <Box w="15%">
                            <Input value="if" disabled border="transparent" />
                          </Box>
                          <Box w="25%">
                            <Input value={xAxis} border="transparent" />
                          </Box>
                          <Box w="20%">
                            <Select
                              style={{
                                width: "80%",
                              }}
                              onChange={handleChangeRangeX}
                              options={[
                                {
                                  value: ">",
                                  label: ">",
                                },
                                {
                                  value: "<",
                                  label: "<",
                                },
                              ]}
                            />
                          </Box>
                          <Box w="40%" mr="15%">
                            <Input
                              placeholder="[comparison value]"
                              border="transparent"
                              onChange={(e) => {
                                setComparisonValueX(e.target.value);
                              }}
                            />
                          </Box>
                        </Flex>
                      </Box>
                    </>
                  ) : null}
                </Box>
                <Box my="2%">
                  {graphType !== "table" &&
                  graphType !== "scorecard" &&
                  yAxis !== null ? (
                    <>
                      <Text align="left" fontWeight="normal">
                        Define Process for Y axis
                      </Text>
                      <Box>
                        <Flex w="120%">
                          <Box w="15%">
                            <Input value="if" disabled border="transparent" />
                          </Box>
                          <Box w="25%">
                            <Select
                              placeholder="Select Data set"
                              allowClear
                              style={{
                                width: "100%",
                                marginRight: "20%",
                                marginTop: "2%",
                              }}
                              onChange={handleColumnRangeY}
                            >
                              {yAxis.map((column) => (
                                <Option value={column} label={column}>
                                  <div className="demo-option-label-item">
                                    <span role="img" aria-label="TMS">
                                      {column}
                                    </span>
                                  </div>
                                </Option>
                              ))}
                            </Select>
                          </Box>
                          <Box w="20%">
                            <Select
                              style={{
                                width: "80%",
                              }}
                              onChange={handleChangeRangeY}
                              options={[
                                {
                                  value: ">",
                                  label: ">",
                                },
                                {
                                  value: "<",
                                  label: "<",
                                },
                              ]}
                            />
                          </Box>
                          <Box w="40%" mr="15%">
                            <Input
                              placeholder="[comparison value]"
                              border="transparent"
                              onChange={(e) => {
                                setComparisonValueY(e.target.value);
                              }}
                            />
                          </Box>
                        </Flex>
                        <Flex mt="5%" flexDir="row-reverse">
                          {buttonStatus === "save" ? (
                            <Button
                              bgColor="#1b1c1e"
                              textColor="white"
                              onClick={handleGraph}
                            >
                              Save Graph
                            </Button>
                          ) : (
                            <Button
                              bgColor="#1b1c1e"
                              textColor="white"
                              onClick={handleEditGraph}
                            >
                              Edit Graph
                            </Button>
                          )}
                        </Flex>
                      </Box>
                    </>
                  ) : null}
                </Box>
              </AccordionPanel>
            </AccordionItem>
            {graphType === "table" ? (
              <AccordionItem>
                <h2>
                  <AccordionButton bgColor="#F7F7F7">
                    <Box flex="1" textAlign="left">
                      Table Name
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box textAlign="left">
                    <FormLabel textAlign="left">Table Name</FormLabel>
                    <Input
                      size="md"
                      onChange={(e) => setDashbaordTableName(e.target.value)}
                    />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ) : graphType === "scorecard" ? (
              <AccordionItem>
                <h2>
                  <AccordionButton bgColor="#F7F7F7">
                    <Box flex="1" textAlign="left">
                      Scorecard values
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex justify="space-between" my="2%">
                    <Box textAlign="left" w="45%">
                      <FormLabel textAlign="left">Primary</FormLabel>
                      <Input size="md" />
                    </Box>
                    <Box textAlign="left" w="45%">
                      <FormLabel textAlign="left">Operations</FormLabel>
                      <Input size="md" />
                    </Box>
                  </Flex>
                  <Flex justify="space-between" my="2%">
                    <Box textAlign="left" w="45%">
                      <FormLabel textAlign="left">Secondary</FormLabel>
                      <Input size="md" />
                    </Box>
                    <Box textAlign="left" w="45%">
                      <FormLabel textAlign="left">Operations</FormLabel>
                      <Input size="md" />
                    </Box>
                  </Flex>
                  <Box textAlign="left">
                    <FormLabel textAlign="left">Title</FormLabel>
                    <Input size="md" />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ) : (
              <AccordionItem>
                <h2>
                  <AccordionButton bgColor="#F7F7F7">
                    <Box flex="1" textAlign="left">
                      Graph Label
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box textAlign="left">
                    <FormLabel textAlign="left">Title</FormLabel>
                    <Input size="md" />
                    <Flex mt="4%">
                      <Box>
                        <Text my="4%">X-axis Title</Text>
                        <Select
                          defaultValue="lucy"
                          style={{
                            width: 120,
                          }}
                          onChange={handleChange}
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "lucy",
                              label: "Lucy",
                            },
                            {
                              value: "disabled",
                              disabled: true,
                              label: "Disabled",
                            },
                            {
                              value: "Yiminghe",
                              label: "yiminghe",
                            },
                          ]}
                        />
                      </Box>
                      <Spacer />
                      <Flex mt="7%">
                        <FormLabel
                          htmlFor="email-alerts"
                          fontSize="14px"
                          mt="4%"
                        >
                          Show title
                        </FormLabel>
                        <Switch
                          id="email-alerts"
                          colorScheme="teal"
                          size="lg"
                        />
                      </Flex>
                    </Flex>

                    <Flex mt="4%">
                      <Box>
                        <Text my="4%">Y-axis Position</Text>
                        <Select
                          defaultValue="lucy"
                          style={{
                            width: 120,
                          }}
                          onChange={handleChange}
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "lucy",
                              label: "Lucy",
                            },
                            {
                              value: "disabled",
                              disabled: true,
                              label: "Disabled",
                            },
                            {
                              value: "Yiminghe",
                              label: "yiminghe",
                            },
                          ]}
                        />
                      </Box>
                      <Spacer />
                      <Box>
                        <Text my="4%">Data Labels</Text>
                        <Select
                          defaultValue="lucy"
                          style={{
                            width: 120,
                          }}
                          onChange={handleChange}
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "lucy",
                              label: "Lucy",
                            },
                            {
                              value: "disabled",
                              disabled: true,
                              label: "Disabled",
                            },
                            {
                              value: "Yiminghe",
                              label: "yiminghe",
                            },
                          ]}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            )}
            <AccordionItem>
              <h2>
                <AccordionButton bgColor="#F7F7F7">
                  <Box flex="1" textAlign="left">
                    {graphType === "table" || graphType === "scorecard"
                      ? "Timeline"
                      : "Display"}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              {graphType === "table" || graphType === "scorecard" ? (
                <AccordionPanel pb={4}>
                  <Box textAlign="left">
                    <FormLabel textAlign="left">Start date</FormLabel>
                    <Input size="md" type="date" />
                  </Box>
                  <Box textAlign="left">
                    <FormLabel textAlign="left">End Date</FormLabel>
                    <Input size="md" type="date" />
                  </Box>
                </AccordionPanel>
              ) : (
                <AccordionPanel pb={4}>
                  <Box textAlign="left">
                    <Text>Data Set</Text>
                    <Flex mt="5%">
                      <Box>
                        <Text>dataset</Text>
                      </Box>
                      <Spacer />
                      <Center>
                        <Popover variant="picker">
                          <PopoverTrigger>
                            <Button
                              aria-label={color}
                              background={color}
                              height="22px"
                              width="22px"
                              padding={0}
                              minWidth="unset"
                              borderRadius={3}
                            ></Button>
                          </PopoverTrigger>
                          <PopoverContent width="170px">
                            <PopoverArrow bg={color} />
                            <PopoverCloseButton color="white" />
                            <PopoverHeader
                              height="100px"
                              backgroundColor={color}
                              borderTopLeftRadius={5}
                              borderTopRightRadius={5}
                              color="white"
                            >
                              <Center height="100%">{color}</Center>
                            </PopoverHeader>
                            <PopoverBody height="120px">
                              <SimpleGrid columns={5} spacing={2}>
                                {colors.map((c) => (
                                  <Button
                                    key={c}
                                    aria-label={c}
                                    background={c}
                                    height="22px"
                                    width="22px"
                                    padding={0}
                                    minWidth="unset"
                                    borderRadius={3}
                                    _hover={{ background: c }}
                                    onClick={() => {
                                      setColor(c);
                                    }}
                                  ></Button>
                                ))}
                              </SimpleGrid>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Center>
                    </Flex>
                    <Flex mt="5%">
                      <Box>
                        <Text>dataset</Text>
                      </Box>
                      <Spacer />
                      <Center>
                        <Popover variant="picker">
                          <PopoverTrigger>
                            <Button
                              aria-label={color}
                              background={color}
                              height="22px"
                              width="22px"
                              padding={0}
                              minWidth="unset"
                              borderRadius={3}
                            ></Button>
                          </PopoverTrigger>
                          <PopoverContent width="170px">
                            <PopoverArrow bg={color} />
                            <PopoverCloseButton color="white" />
                            <PopoverHeader
                              height="100px"
                              backgroundColor={color}
                              borderTopLeftRadius={5}
                              borderTopRightRadius={5}
                              color="white"
                            >
                              <Center height="100%">{color}</Center>
                            </PopoverHeader>
                            <PopoverBody height="120px">
                              <SimpleGrid columns={5} spacing={2}>
                                {colors.map((c) => (
                                  <Button
                                    key={c}
                                    aria-label={c}
                                    background={c}
                                    height="22px"
                                    width="22px"
                                    padding={0}
                                    minWidth="unset"
                                    borderRadius={3}
                                    _hover={{ background: c }}
                                    onClick={() => {
                                      setColor(c);
                                    }}
                                  ></Button>
                                ))}
                              </SimpleGrid>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Center>
                    </Flex>
                  </Box>
                </AccordionPanel>
              )}
            </AccordionItem>
          </Accordion>
        </Box>
      ) : null}

      <ChakraModal
        title="Select Widget"
        isOpen={addWidgetModalisOpen}
        onOpen={addWidgetModalOnOpen}
        onClose={addWidgetModalOnClose}
        size="4xl"
      >
        <CreateWidget setGraphType={setGraphType} />
      </ChakraModal>

      <ChakraModal
        title="Choose Template"
        isOpen={addTemplateModalisOpen}
        onOpen={addTemplateModalOnOpen}
        onClose={addTemplateModalOnClose}
        size="4xl"
      >
        <TemplateModal setSelectedTemplate={setSelectedTemplate} />
      </ChakraModal>
    </Flex>
  );
};

export default BusinessDashboard;
