import create from "zustand";
import { persist } from "zustand/middleware";

let odinStore = (set, get) => ({
  isLoggedIn: false,
  accessToken:null,
  setIsLoggedIn: loggedIn => set(state => ({ isLoggedIn: loggedIn })),
  setAccessToken: accessToken => set(state => ({ accessToken: accessToken })),
});

odinStore = persist(odinStore, { name: "odin" });

export const useOdinStore = create(odinStore);
