import React, { useEffect, useState } from "react";

import {
  chakra,
  Box,
  Button,
  Flex,
  Image,
  Grid,
  GridItem,
  Input,
  InputGroup,
  Text,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  Navigate,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import NewData from "../NewData";
import { BASE_URL } from "../../../../constants/api";
import qs from "qs";
import { useOdinStore } from "store";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { isLoggedIn, setIsLoggedIn, setAccessToken } = useOdinStore();
  // Other Hooks
  const {
    handleSubmit,
    register,
    clearErrors,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = (values) => {
    try {
      const body = {
        username: values.username,
        password: values.password,
      };

      console.log("body------------>", body);
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(body),
        url: `${BASE_URL}/user/login`,
      };

      axios(options)
        .then((res) => {
          if (res.data.access_token) {
            console.log("res------->", res.data);
            setAccessToken(res.data.access_token);
            setIsLoggedIn(true);
          }
        })
        .catch((err) => {
          console.error("Login Failed: ", err);
          setError("serverError", {
            message: err?.response?.data?.detail || err.toString(),
          });
        });
    } catch (err) {
      setError("serverError", {
        message: err?.response?.data?.detail || err.toString(),
      });
    }

    setTimeout(() => clearErrors(["serverError"]), 3000);
  };

  useEffect(() => {
    console.log("is logged------->", isLoggedIn);
  }, [isLoggedIn]);

  return (
    <>
      <Box w="full">
        {/* <Flex>
            <Image src={csquare} />
          </Flex> */}
        {isLoggedIn && <Navigate to="/" replace={true} />}
        <Flex flexDir="column" w="container" alignItems="center" mt="10%">
          <Text fontSize="3xl" textAlign="center" fontWeight={400}>
            Login
          </Text>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.username} mt={6}>
              <Input
                placeholder="Username"
                w="md"
                mt={4}
                {...register("username", {
                  required: "Username is required",
                })}
              />
              {errors.username && (
                <FormErrorMessage>{errors.username.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.password}>
              <InputGroup w="md" mt={4}>
                <Input
                  type="password"
                  placeholder="Password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
              </InputGroup>
              {errors.password && (
                <FormErrorMessage>{errors.password.message}</FormErrorMessage>
              )}
            </FormControl>

            <Button
              mt={10}
              w="full"
              type="submit"
              bgColor="black"
              color="white"
              isLoading={isSubmitting}
            >
              Sign In
            </Button>
            {errors.serverError && (
              <Text color="red" mt={4} textAlign="left">
                {errors.serverError.message}
              </Text>
            )}
          </chakra.form>
        </Flex>
      </Box>
    </>
  );
};

export default Login;
