import { Box, Button, Flex, SimpleGrid, Spacer,Text, useDisclosure } from '@chakra-ui/react'
import Chart from 'components/Chart';
import ChakraModal from 'components/Modal/ChakraModal';
import AddNewGraphForm from 'components/Modal/Form/AddNewGraphForm';
import { useGraphStore } from 'graphStore';
import React, { useEffect, useState } from 'react'

function BusinessExplorer() {
    const {graphType,xValue,yValue} = useGraphStore()

    const [graphs, setGraphs] = useState([])
    const {
        isOpen: addGraphModalisOpen,
        onOpen: addGraphModalOnOpen,
        onClose: addGraphModalOnClose,
    } = useDisclosure();

    const handleAddClick = () => {
        // open modal
    
        addGraphModalOnOpen();
    };

    useEffect(()=>{

        console.log(graphType)
        console.log("x value----->",xValue)
        //console.log("x values--------->",[...xValue].map(i => Object.values(i)).flat(2))
        console.log("y value--------->",yValue)
        
    },[graphType,xValue,yValue])

    useEffect(() => {
        if(yValue.length > 0){
            setGraphs(graphs => [...graphs, {
                graphType:graphType,
                //xValue:[...xValue].map(i => Object.values(i)).flat(2),
                xValue:xValue,
                yValue:yValue,
            }])
        }
    }, [yValue])
    
  return (
    <>
        <Box w="130%" mt="6%">
            <Flex  pb={5} borderBottomWidth="1px"  >
                <Text fontSize='xl' fontWeight="bold" align="left" ml="4%" mt="1%">Analytics</Text>
                <Spacer />
                <Button size="md" color="white" bgColor="black" w="15%" mr="5%" onClick={handleAddClick}>Add New Graph</Button>
            </Flex>
            <Box  p="10" >
                <Box  mx="auto">
                    {
                        graphs.length > 0 ? (
                            <SimpleGrid columns={2} spacing={5}>
                                {
                                    graphs.map((graph)=>(
                                        <Chart type={graph.graphType} x ={graph.xValue} y={graph.yValue}/>
                                    ))
                                }
                            </SimpleGrid>
                        ) : null
                    } 
                </Box>
            </Box>
            
            <ChakraModal
                title="Add new Graph"
                isOpen={addGraphModalisOpen}
                onOpen={addGraphModalOnOpen}
                onClose={addGraphModalOnClose}
                size="2xl"
            >
                <AddNewGraphForm
                    onClose={addGraphModalOnClose}
                />
            </ChakraModal>
        </Box>
        
    </>
  )
}

export default BusinessExplorer