import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const ChakraModal = ({
  title,
  isOpen,
  onOpen,
  onClose,
  children,
  buttonText,
  ...props
}) => {


  return (
    <>
      {/* <Button onClick={onOpen}>{buttonText}</Button> */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="scale"
        style={{ marginRight: 8, color: "#F26B6E" }}
        scrollBehavior="inside"
        allowPinchZoom
        preserveScrollBarGap
        {...props}
      >
        <ModalOverlay />
        <ModalContent mx={50}>
          <ModalHeader>
            {title && (
              <Heading fontSize="xl" top={10} left={10} marginTop={1} marginLeft={2} color="iconLabel">
                {title}
              </Heading>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChakraModal;
