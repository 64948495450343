// Landing Page
import Odin from 'pages/private';
import React, { useEffect } from 'react'
import { useOdinStore } from 'store';

function Dashboard() {
    const { isLoggedIn, setIsLoggedIn } = useOdinStore();
    if(isLoggedIn === true){
        return <Odin />
    }
  return (
    <div>Dashboard</div>
  )
}

export default Dashboard