import { Flex, FormLabel, Text } from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { GrGraphQl } from "react-icons/gr";

function Chart({ type, x, y, operator, result }) {
  let OptionsChart = {
    chart: {
      type: type,
      borderColor: "#A0A8B9",
      borderWidth: 0.25,
      //shadow: true
    },
    title: {
      text: " ",
    },
    xAxis: {
      title: {
        text: null,
      },
      categories: x,
      labels: { enabled: true },
    },
    yAxis: {
      min: 0,
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        // dataLabels: {
        //     enabled: true
        // }
      },
    },
    series: y,
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={OptionsChart} />
    </>
  );
}

export default Chart;
