/**
 * @DESC
 * Color Usage: <Box bg="gray.100">Welcome</Box>
 * or <Text color="primary">Hi</Text>
 */

const colors = {
  gray: {
    50: "#F2F2F2",
    100: "#DBDBDB",
    200: "#C4C4C4",
    300: "#ADADAD",
    400: "#969696",
    500: "#808080",
    600: "#666666",
    700: "#4D4D4D",
    800: "#333333",
    900: "#1A1A1A",
  },
  primary: "#24a0ed",
  secondary: "#808000",
  tertiary: "#F4EFA7",
  white: "#FFFFFF",
  blue: "#304FFE",
  // We can define custom variants through: https://chakra-ui.com/docs/theming/advanced
  // https://chakra-ui.com/docs/theming/customize-theme

  // Example Video: https://www.youtube.com/watch?v=Tx54S3UtRjI
};

export default colors;
