import {
  Grid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Heading,
  Text,
  Flex,
  Icon,
  Select,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
  IconButton,
  Button,
  Progress,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { MdBuild, MdCall } from "react-icons/md";
import { IoIosAnalytics } from "react-icons/io";
import { DownloadIcon, Search2Icon } from "@chakra-ui/icons";
import { Table } from "antd";
import axios from "axios";
import { BASE_URL } from "constants/api";
import { useOdinStore } from "store";

// import LineageVisualizer from "./LineageVisualizer";
import SqlEditor from "./SqlEditor";
import CustomSqlEditor from "./CustomSqlEditor";


const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
  },
  {
    key: "4",
    name: "Disabled User",
    age: 99,
    address: "Sidney No. 1 Lake Park",
  },
];

function Scheduler() {
  const { accessToken } = useOdinStore();
  const [databases, setDatabases] = useState([]);

  const [visualizerIsVisible, setVisualizerIsVisible] = useState(false);
  const [queryText, setQueryText] = useState("");
  const [customEditorIsVisible, setCustomEditorIsVisible] = useState(false);
  const [tables, setTables] = useState([])
  const [column, setColumn] = useState([])
  const [databaseName, setDatabaseName] = useState(null)
  const [tableName, setTableName] = useState(null)
  const [columnName, setColumnName] = useState(null)
  const [action, setAction] = useState(null)
  const [queryColumn, setQueryColumn] = useState([])
  const [queryData, setQueryData] = useState([])
  const [result, setResult] = useState(null)
  const [arithmaticOperations, setArithmaticOperations] = useState([])

    useEffect(() => {
        const res = axios.get(`${BASE_URL}/db_list`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "ngrok-skip-browser-warning":"any"
                }
            })
              .then((response) => {
                  setDatabases(response.data.databases)
              })
              .catch((error)=> {
                  console.log(error);
              });
    }, [])

    useEffect(()=>{
      
        if(databaseName !== null){
            const res = axios.get(`${BASE_URL}/get_db_table_details`,{
                params:{
                    dw_name: databaseName,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "ngrok-skip-browser-warning":"any"
                  }
            }).then((response) => {
                console.log("response data ----------------->",response.data)
                setTables(response.data.database_details)
                
            })
            .catch((error)=> {
                console.log(error);
            });
        }
        
    },[databaseName])

    useEffect(()=>{
        const res = axios.get(`${BASE_URL}/get_single_table_details`,{
            params:{
                dw_name: databaseName,
                table_name:tableName
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "ngrok-skip-browser-warning":"any"
            }
        }).then((response) => {
            setColumn(response.data.table_columns)
            
        })
        .catch((error)=> {
            console.log(error);
        });
    },[tableName])

    useEffect(()=>{
        if(action !== null && columnName!== null){
            const res = axios.post(`${BASE_URL}/arithmatic_operation`,{
              dw_name: databaseName,
              table_name:tableName,
              column_name:[columnName],
              arithmatic_operation:action
          },{
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "ngrok-skip-browser-warning":"any"
                  }
            }).then((response) => {
                setResult(response.data.result[0].value)
                
            })
            .catch((error)=> {
                console.log(error);
            });
        }
        
    },[columnName,action])

  useEffect(() => {
    const res = axios
      .get(`${BASE_URL}/db_list`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning":"any"
        },
      })
      .then((response) => {
        setDatabases(response.data.databases);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  useEffect(() => {
    const res = axios
      .get(`${BASE_URL}/supported_arithmatic_operations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning":"any"
        },
      })
      .then((response) => {
        setArithmaticOperations(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  const runQuery =async () =>{


    const res = await axios.post(`${BASE_URL}/ide_query`,
      {
        dw_name : databaseName,
        sql_query : queryText            
      },{
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning":"any"
        },
      },)
      .then((response) =>{
        console.log(response.data);
        setQueryData(response.data.query_response)
        let arrays=[]
        const arr= response.data.columns
        for (let i = 0; i < arr.length; i++) {
          arrays.push({
            title: arr[i], dataIndex: arr[i], key: arr[i]
          })
        }
        setQueryColumn(arrays)
      })
      .catch( (error) => {
        console.log(error);       
      });
  }



  return (
    <Grid templateColumns="4fr 16fr " gridTemplateRows="auto" w="133%">
      <Box borderRightWidth="1px" overflowX="hidden" overflowY="auto">
        <Box
          borderBottomWidth="1px"
          borderBottomColor="#A3A3A3"
          w="100%"
          pb={5}
          pt={5}
          pl={5}
        >
          <Text align="left" fontSize="md" fontWeight="bold">
            Database
          </Text>
          <Flex ml="3%" mt="4%" w="80%">
            <Select
              variant="filled"
              placeholder="Select Database"
              onChange={(event) => setDatabaseName(event.target.value)}
            >
              {databases.map((database) => (
                <option value={database}>{database}</option>
              ))}
            </Select>
          </Flex>
        </Box>
        <Box
          borderBottomWidth="1px"
          borderBottomColor="#A3A3A3"
          w="120%"
          pb={5}
          pt={5}
          pl={5}
          pr={5}
        >
          <Text align="left" fontSize="md" fontWeight="bold">
            Transaction type
          </Text>
          <Flex ml="3%" mt="4%" w="80%">
            <Select variant="filled" placeholder="Sanpshot" />
          </Flex>
        </Box>
        <Box
          borderBottomWidth="1px"
          borderBottomColor="#A3A3A3"
          w="120%"
          pb={5}
          pt={5}
          pl={5}
          pr={5}
        >
          <Text align="left" fontSize="md" fontWeight="bold">
            Transaction type
          </Text>
          <Flex ml="3%" mt="4%" w="50%">
            <Select variant="filled" placeholder="By Day" />
          </Flex>
          <Flex ml="3%" mt="10%" mb="10%" w="100%">
            <Text fontWeight="bold">Every</Text>
            <Input ml="10%" htmlSize={4} size="xs" width="auto" />
            <Text ml="10%" fontWeight="bold">
              Day
            </Text>
          </Flex>
          <Flex ml="3%" mt="10%" mb="10%" w="100%">
            <Text fontWeight="bold">At</Text>
            <NumberInput
              ml="10%"
              defaultValue={0}
              min={0}
              max={12}
              size="xs"
              width="30%"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text ml="10%" fontWeight="bold">
              AM
            </Text>
          </Flex>
          <Flex ml="3%" mt="4%" w="80%">
            <Select variant="filled" placeholder="EUROPE/LONDON" />
          </Flex>
        </Box>
        <Box
          overflowX="hidden"
          overflowY="auto"
          h="380px"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#1b1c1e",
              borderRadius: "4px",
            },
          }}
        >
          <Box
            borderBottomWidth="1px"
            borderBottomColor="#A3A3A3"
            w="100%"
            pb={5}
            pl={4}
            pr={4}
            pt={5}
          >
            <Flex ml="3%" mt="4%">
              <Text align="left" fontSize="md" fontWeight="bold">
                Build Policy
              </Text>
              <Spacer />
              <Icon color="#888888" />
              <Text ml="4%" color="#888888" fontSize="xs">
                Edit
              </Text>
            </Flex>
          </Box>
          <Box
            borderBottomWidth="1px"
            borderBottomColor="#A3A3A3"
            w="100%"
            pb={5}
            pt={5}
            pl={4}
            pr={4}
          >
            <Flex ml="3%" mt="4%">
              <Text align="left" fontSize="md" fontWeight="bold">
                Maximum Duration
              </Text>
              <Spacer />
              <Icon color="#888888" />
              <Text ml="4%" color="#888888" fontSize="xs">
                Edit
              </Text>
            </Flex>
          </Box>
          <Box
            borderBottomWidth="1px"
            borderBottomColor="#A3A3A3"
            w="100%"
            pb={5}
            pt={5}
            pl={4}
            pr={4}
          >
            <Flex ml="3%" mt="4%">
              <Text align="left" fontSize="md" fontWeight="bold">
                Transformers
              </Text>
              <Spacer />
              <Icon color="#888888" />
              <Text ml="4%" color="#888888" fontSize="xs">
                Edit
              </Text>
            </Flex>
          </Box>
          <Box
            borderBottomWidth="1px"
            borderBottomColor="#A3A3A3"
            w="100%"
            pb={5}
            pl={4}
            pr={4}
            pt={5}
          >
            <Flex ml="3%" mt="4%">
              <Text align="left" fontSize="md" fontWeight="bold">
                Completion Strategies
              </Text>
              <Spacer />
              <Icon color="#888888" />
              <Text ml="4%" color="#888888" fontSize="xs">
                Edit
              </Text>
            </Flex>
          </Box>
          <Box
            borderBottomWidth="1px"
            borderBottomColor="#A3A3A3"
            w="100%"
            pb={5}
            pt={5}
            pl={4}
            pr={4}
          >
            <Flex ml="3%" mt="4%">
              <Text align="left" fontSize="md" fontWeight="bold">
                Enforce consistent schema
              </Text>
              <Spacer />
              <Icon color="#888888" />
              <Text ml="4%" color="#888888" fontSize="xs">
                Edit
              </Text>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box
        p="2%"
        // pt="3%"
        // pb="2%"
        borderBottomColor="#1b1c1e"
        h="50%"
      >
        <Text align="left" fontWeight="bold">
          Sync configuration
        </Text>

        <Box
          mt="2%"
          borderWidth="1px"
          borderColor="gray.100"
          h="100%"
          w="100%"
          // pt="1%"
          p="2%"
          // pb="1%"
        >
          <Flex align="center" justify="space-between">
            <Text fontWeight="bold" align="left" fontSize="sm">
              SQL Editor
            </Text>
            <Button
              ml="auto"
              bg="white"
              borderWidth={1}
              boxShadow="xl"
              disabled={visualizerIsVisible ? true : false}
              onClick={() => setCustomEditorIsVisible(!customEditorIsVisible)}
            >
              {customEditorIsVisible ? "SQL IDE" : "Custom Editor"}
            </Button>
            <Button
              ml={2}
              zIndex={10}
              bg="white"
              borderWidth={1}
              boxShadow="xl"
              onClick={() => setVisualizerIsVisible(!visualizerIsVisible)}
              transition="0.5s ease-in"
            >
              {visualizerIsVisible ? "Go to Editor" : "Visualize"}
            </Button>
          </Flex>

          <Grid
            templateRows="5fr 2fr"
            gridTemplateColumns="auto"
            h="80%"
            mt="5px"
          >
            {visualizerIsVisible ? (
                <p>remove</p>
            //   <LineageVisualizer
            //     {...{
            //       visualizerIsVisible,
            //       setVisualizerIsVisible,
            //       queryText,
            //     }}
            //   />
            ) : (
              <>
                {customEditorIsVisible ? (
                  <CustomSqlEditor
                    {...{
                      visualizerIsVisible,
                      setVisualizerIsVisible,
                      accessToken,
                      queryText,
                      setQueryText,
                    }}
                  />
                ) : (
                  <>
                  <SqlEditor
                    {...{
                      visualizerIsVisible,
                      setVisualizerIsVisible,
                      queryText,
                      setQueryText,
                    }}
                  />
                  
                  </>
                )}
              </>
            )}
          </Grid>
          <Flex align="center" flexDir="row-reverse" justify="space-between">
            <Button
              ml={2}
              zIndex={10}
              bg="white"
              borderWidth={1}
              boxShadow="xl"
              onClick={runQuery}
              transition="0.5s ease-in"
            >
              Run
            </Button>
          </Flex>
          
        </Box>
        <Flex mt="2%" borderWidth="1px" borderColor="gray.100" w="90%" pt="1%" pl="2%" pb="1%"> 
                    <Select placeholder='Select Action' w="20%" onChange={(event)=>setAction(event.target.value) }>
                      {arithmaticOperations.map((operation) => (
                        <option value={operation}>{operation}</option>
                      ))}
                    </Select>
                    <Select placeholder='Select Table Name' w="20%" ml="3%" onChange={(event)=>setTableName(event.target.value) }>
                        {
                            tables.map((table)=>(
                                <option value={table.table_name}>{table.table_name}</option>
                            ))
                        }                               
                    </Select>
                    <Select placeholder='Select Column ' w="20%" ml="3%" onChange={(event)=>setColumnName(event.target.value) }>
                        {
                            column.map((columns)=>(
                                <option value={columns.column_name}>{columns.column_name}</option>
                            ))
                        }                               
                    </Select>
                    <Spacer />
                    <Text fontWeight="bold" fontSize="lg" mt="0.5%" mr="3%">Result : </Text>
                    <Text fontWeight="bold" fontSize="lg" mt="0.5%" mr="3%">{result}</Text>
                </Flex>

        <Divider width="100%" h="5px" color="#1b1c1e" />

        <Box mt="2%">
          <Flex pl="3%">
            <Button
              leftIcon={<MdBuild />}
              size="xs"
              borderRadius="md"
              w="7%"
              variant="solid"
            >
              Object
            </Button>
            <Button
              leftIcon={<MdBuild />}
              size="xs"
              ml="1%"
              borderRadius="md"
              w="7%"
              variant="solid"
            >
              Query
            </Button>
            <Button
              leftIcon={<MdBuild />}
              size="xs"
              ml="1%"
              borderRadius="md"
              w="7%"
              variant="solid"
            >
              Result
            </Button>
            <Button
              leftIcon={<IoIosAnalytics />}
              size="xs"
              ml="1%"
              borderRadius="md"
              w="7%"
              variant="outline"
            >
              Chart
            </Button>
            <Spacer />
            <Search2Icon />
            <DownloadIcon ml="2%" />
          </Flex>
        </Box>
        <Grid templateColumns="12fr 4fr " gridTemplateRows="auto" mt="2%">
          <Table dataSource={queryData} columns={queryColumn}  scroll={{ y: 180 }} />
          <Box
            h="300px"
            overflowX="hidden"
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "2px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#1b1c1e",
                borderRadius: "4px",
              },
            }}
          >
            <Box m="2%" borderRadius="md" bgColor="#D9D9D9" p="5%">
              <Flex>
                <Text fontSize="xs" fontWeight="bold">
                  Query Details
                </Text>
                <Spacer />
              </Flex>
              <Flex mt="5%">
                <Text fontSize="xs" fontWeight="bold">
                  Query Duration
                </Text>
                <Spacer />
                <Text fontSize="xs" fontWeight="bold">
                  218 ms
                </Text>
              </Flex>
              <Box mt="5%">
                <Progress value={100} size="xs" colorScheme="pink" />
              </Box>
              <Flex mt="5%">
                <Text fontSize="xs" fontWeight="bold">
                  Rows
                </Text>
                <Spacer />
                <Text fontSize="xs" fontWeight="bold">
                  218{" "}
                </Text>
              </Flex>
            </Box>
            <Box m="2%" borderRadius="md" bgColor="#D9D9D9" p="5%">
              <Flex>
                <Text fontSize="xs" fontWeight="bold">
                  Name
                </Text>
                <Spacer />
                <Text fontSize="xs" fontWeight="bold">
                  Aa
                </Text>
              </Flex>
              <Flex mt="5%">
                <Text fontSize="xs" fontWeight="normal">
                  100% filled
                </Text>
                <Spacer />
              </Flex>
              <Box mt="5%">
                <Progress value={100} size="xs" colorScheme="pink" />
              </Box>
            </Box>
            <Box m="2%" borderRadius="md" bgColor="#D9D9D9" p="5%">
              <Flex>
                <Text fontSize="xs" fontWeight="bold">
                  Query Details
                </Text>
                <Spacer />
              </Flex>
              <Flex mt="5%">
                <Text fontSize="xs" fontWeight="bold">
                  Query Duration
                </Text>
                <Spacer />
                <Text fontSize="xs" fontWeight="bold">
                  218 ms
                </Text>
              </Flex>
              <Box mt="5%">
                <Progress value={100} size="xs" colorScheme="pink" />
              </Box>
              <Flex mt="5%">
                <Text fontSize="xs" fontWeight="bold">
                  Rows
                </Text>
                <Spacer />
                <Text fontSize="xs" fontWeight="bold">
                  218{" "}
                </Text>
              </Flex>
            </Box>
            <Box m="2%" borderRadius="md" bgColor="#D9D9D9" p="5%">
              <Flex>
                <Text fontSize="xs" fontWeight="bold">
                  Query Details
                </Text>
                <Spacer />
              </Flex>
              <Flex mt="5%">
                <Text fontSize="xs" fontWeight="bold">
                  Query Duration
                </Text>
                <Spacer />
                <Text fontSize="xs" fontWeight="bold">
                  218 ms
                </Text>
              </Flex>
              <Box mt="5%">
                <Progress value={100} size="xs" colorScheme="pink" />
              </Box>
              <Flex mt="5%">
                <Text fontSize="xs" fontWeight="bold">
                  Rows
                </Text>
                <Spacer />
                <Text fontSize="xs" fontWeight="bold">
                  218{" "}
                </Text>
              </Flex>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}

export default Scheduler;
