import React,{ useEffect, useState } from 'react';
import {
    Box,
    Button,
    chakra,
    Center,
    Flex,
    FormErrorMessage,
    Grid,
    Text,
    Select as ChakraSelect,
    FormLabel,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import axios from 'axios';
import { notification } from 'antd';
import { BASE_URL } from 'constants/api';
import { useOdinStore } from 'store';
import { useGraphStore } from 'graphStore';
import Select from "react-select";
import { DatePicker } from "antd";
import {
  AiOutlineLineChart
} from "react-icons/ai";
import moment from "moment";
import {FcBarChart} from "react-icons/fc"


const ChakraDatePicker = chakra(DatePicker);


const AddNewGraphForm = ({ buttonText, onClose, children, ...props }) => {
    const { accessToken } = useOdinStore()
    const { setGraphType, setXValue,setYValue } = useGraphStore()
    const [graph, setGraph] = useState(null)
    const [dbs, setDbs] = useState([])
    const [tables, setTables] = useState([])
    const [columns, setColumns] = useState([])
    const [databaseName, setDatabaseName] = useState(null)
    const [tableName, setTableName] = useState(null)
    const [xAxis, setXAxis] = useState(null)
    const [yAxis, setYAxis] = useState(null)
    const [xValues, setXValues] = useState([])
    const [yValues, setYValues] = useState([])
  // The label supports JSX.
  const options = [
    {
      value: "bar",
      label: (
        <Flex>
            <Box mr="5%" mt="2%">
                <FcBarChart />
            </Box> 
            <Text>Bar Chart</Text>
        </Flex>
      )
    },
    {
      value: "line",
      label: (
        <Flex>
            <Box mr="5%" mt="2%">
                <AiOutlineLineChart />
            </Box> 
            <Text>Line Chart</Text>
        </Flex>
      )
    },
  ];

    /**
     * graphType = [{id:, x: , y:, component: <Bar, etc/>, }]
     */

    const {
        handleSubmit,
        register,
        setValue,
        setError,
        clearErrors,
        formState: {errors, isSubmitting}
    } = useForm()


    useEffect(() => {
        console.log("token---------->",accessToken)
        const res = axios.get(`${BASE_URL}/db_list`,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }).then((res)=>{
            console.log("dbs-------------->",res.data.databases)
            setDbs(res.data.databases)
        })
        .catch( (error) => {
            console.log(error);
          });
    }, [accessToken])
    
    useEffect(()=>{
        if(databaseName !== null){
            const res = axios.get(`${BASE_URL}/get_db_table_details`,{
                params:{
                    dw_name: databaseName,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`
                  }
            }).then((response) => {
                setTables(response.data.database_details)
                
            })
            .catch((error)=> {
                console.log(error);
            });
        }
        
    },[databaseName])

    useEffect(()=>{
        const res = axios.get(`${BASE_URL}/get_single_table_details`,{
            params:{
                dw_name: databaseName,
                table_name:tableName
            },
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then((response) => {
            setColumns(response.data.table_columns)
            
        })
        .catch((error)=> {
            console.log(error);
        });
    },[tableName])


    const onSubmit = async (body)=>{
        const res = await axios.post(`${BASE_URL}/graph_operation`,
        {
            dw_name: databaseName,
            table_name:tableName,
            column_name_x:xAxis,
            column_name_y:yAxis,
          },{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },)
          .then(async(response) =>{
            console.log(response.data.result);
            setXValues(response.data.result.X)
            setXValue(response.data.result.X)
            setYValues(response.data.result.Y)
            setYValue(response.data.result.Y)
            console.log("graphs------------------->",graph)
            setGraphType(graph)
            notification.success({
                message: `Graph Created`,
                placement:'bottom',
            });
            onClose()
          })
          .catch( (error) => {
            console.log(error);
            notification.error({
                message: `Unable to create Graph `,
                placement:'bottom',
            });
        });

        
    }

    function selectGraph(event) {
        setGraph(event.value)
    }
    
    const handleFromDateChange = (dateString) => {
        console.log(dateString);
    };
    const handleToDateChange = (dateString) => {
        console.log(dateString);
    };
    return(
        <>
            <Center>
                <Box d="flex" flexDir="column" {...props} justifyContent="center">
                    <chakra.form onSubmit={handleSubmit(onSubmit)}>
                        <Flex>
                            <Grid
                                templateColumns="repeat(2,1fr)"
                                gap={6}
                                m={6}
                            >
                                <FormLabel>Graph type</FormLabel>
                                <Select placeholder='Select Graph Type'  options={options} onChange={selectGraph} />
                                <FormLabel>Database Name</FormLabel>
                                <ChakraSelect placeholder='Select Database Name' onChange={(event)=>setDatabaseName(event.target.value) }>
                                    {
                                        dbs.map((db)=>(
                                            <option value={db}>{db}</option>
                                        ))
                                    }                               
                                </ChakraSelect>
                                <FormLabel>Table Name</FormLabel>
                                <ChakraSelect placeholder='Select Table Name' w="100%" onChange={(event)=>setTableName(event.target.value) }>
                                    {
                                        tables.map((table)=>(
                                            <option value={table.table_name}>{table.table_name}</option>
                                        ))
                                    }                               
                                </ChakraSelect>
                                <FormLabel>From</FormLabel>
                                <ChakraDatePicker
                                    style={{ height: "100%", width: "100%", borderRadius: "8px" }}
                                    defaultValue={moment()}
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    onChange={(date, dateString) => handleFromDateChange(dateString)}
                                    
                                />
                                <FormLabel>To</FormLabel>
                                <ChakraDatePicker
                                    style={{ height: "100%", width: "100%", borderRadius: "8px" }}
                                    defaultValue={moment()}
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    onChange={(date, dateString) => handleToDateChange(dateString)}
                                    
                                />
                                <FormLabel>X Axis</FormLabel>
                                <ChakraSelect placeholder='Select Column for X Axis' w="100%" onChange={(event)=>setXAxis(event.target.value) }>
                                    {
                                        columns.map((columns)=>(
                                            <option value={columns.column_name}>{columns.column_name}</option>
                                        ))
                                    }                               
                                </ChakraSelect>

                                <FormLabel>Y Axis</FormLabel>
                                <ChakraSelect placeholder='Select Column for Y Axis' w="100%" onChange={(event)=>setYAxis(event.target.value) }>
                                    {
                                        columns.map((columns)=>(
                                            <option value={columns.column_name}>{columns.column_name}</option>
                                        ))
                                    }                               
                                </ChakraSelect>
                            </Grid>
                        </Flex>
                        
                        <Flex
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            flexDir="row"
                            pt={5}
                            mb={7}
                            wrap="wrap"
                        >
                            <Button bg="#ffffff" w="20%" size='md' m={2} borderWidth={2} onClick={onClose}><Text color="#1b1c1e">Cancel</Text></Button>
                            <Button 
                                size='md'
                                mr="5%"
                                mt={2}
                                mb={2}
                                ml={2}
                                bg="#1b1c1e"
                                w="20%"
                                type="submit"
                            ><Text color="#ffffff">Save</Text></Button>
                        </Flex>
                    </chakra.form>
                </Box>
            </Center>
        </>
    )
}

export default AddNewGraphForm