import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Select as ChakraSelect,
  Spacer,
  Icon,
} from "@chakra-ui/react";
import { useOdinStore } from "store";
import {
  AiOutlineAreaChart,
  AiOutlineLineChart,
  AiOutlinePieChart,
  AiOutlineTable,
} from "react-icons/ai";
import { GrScorecard } from "react-icons/gr";

const CreateWidget = ({
  buttonText,
  onClose,
  setGraphType,
  children,
  ...props
}) => {
  const Graphs = [
    {
      id: 1,
      graph: "Line Chart",
      value: "line",
      icon: AiOutlineLineChart,
      type: true,
      types: [
        {
          graphName: "Basic Line",
          icon: AiOutlineLineChart,
        },
        {
          graphName: "Basic Line with data labels",
          icon: AiOutlineLineChart,
        },
        {
          graphName: "Time data with irregular intervals",
          icon: AiOutlineLineChart,
        },
      ],
    },
    {
      id: 2,
      graph: "Area Chart",
      value: "area",
      type: false,
      icon: AiOutlineAreaChart,
      types: [],
    },
    {
      id: 3,
      graph: "Bar Chart",
      value: "bar",
      type: false,
      icon: AiOutlineAreaChart,
      types: [],
    },
    {
      id: 4,
      graph: "Pie Chart",
      value: "pie",
      type: true,
      icon: AiOutlinePieChart,
      types: [
        {
          graphName: "Pie chart with legends",
          icon: AiOutlinePieChart,
        },
        {
          graphName: "Pie chart with gradient fill",
          icon: AiOutlinePieChart,
        },
      ],
    },
    {
      id: 5,
      graph: "Spline Chart",
      value: "spline",
      type: false,
      icon: AiOutlineLineChart,
      types: [],
    },
    {
      id: 6,
      graph: "Table",
      value: "table",
      type: false,
      icon: AiOutlineTable,
      types: [],
    },
    {
      id: 7,
      graph: "Score Card",
      value: "scorecard",
      type: false,
      icon: GrScorecard,
      types: [],
    },
  ];

  const [graph, setGraph] = useState(null);
  const { accessToken } = useOdinStore();
  const handleAddClick = (id) => {
    let obj = Graphs.find((o) => o.id === id);
    setGraph(obj);
    console.log("type obj--------->", obj);
    setGraphType(obj.value);
    onClose();
  };

  return (
    <>
      <Box d="flex" flexDir="column" justifyContent="center">
        <Flex borderColor="#EEEEEE" borderWidth="2px">
          <Box w="30%" borderRightWidth="1px" borderColor="#EEEEEE">
            {Graphs.map((graph) => (
              <Flex
                borderWidth="1px"
                py="4%"
                px="3%"
                borderColor="#EEEEEE"
                _hover={{ backgroundColor: "gray" }}
                onClick={() => handleAddClick(graph.id)}
              >
                <Box mt="2%">
                  <Icon as={graph.icon} />
                </Box>
                <Text ml="3%" mt="0%" fontSize="lg" color="black">
                  {graph.graph}
                </Text>
              </Flex>
            ))}
          </Box>
          <Box w="70%">
            {graph !== null && graph.type === true ? (
              <>
                {graph.types.map((type) => (
                  <Flex p="2%">
                    <Box mt="2%">
                      <Icon as={type.icon} />
                    </Box>
                    <Text ml="3%" mt="1%" fontSize="lg" color="black">
                      {type.graphName}
                    </Text>
                    <Spacer />
                    <Button w="20%">Select</Button>
                  </Flex>
                ))}
              </>
            ) : null}
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default CreateWidget;
