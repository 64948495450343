import create from "zustand";
import { persist } from "zustand/middleware";

let graphStore = (set, get) => ({
  graphType: null,
  xValue:[],
  yValue:[],
  operator:null,
  results:[],
  setGraphType: type => set(state => ({ graphType: type })),
  setXValue: values => set(state => ({ xValue: values })),
  setYValue: values => set(state => ({ yValue: values })),
  setOperator: value => set(state => ({ operator: value })),
  setResults: value => set(state => ({ results: value })),
});

graphStore = persist(graphStore, { name: "graph" });

export const useGraphStore = create(graphStore);
