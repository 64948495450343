import {
  Box,
  IconButton,
  LinkOverlay,
  LinkBox,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { FiPlay } from "react-icons/fi";

const PreviewButton = ({ ...props }) => {
  

  return (
    <>
      <LinkBox>
        <LinkOverlay
          isExternal
        >
          <Box
            // as="button"
            boxShadow="xl"
            borderRadius="12px"
            //outline="none"
            border={0}
            {...props}
          >
            <IconButton
              variant="previewIcon"
              aria-label="Preview"
              fontSize="20px"
              // size="lg"
              icon={<FiPlay size={26} strokeWidth={2} />}
            />
          </Box>
        </LinkOverlay>
      </LinkBox>
      {/* <Button onClick={toggleColorMode}>
        Toggle {colorMode === "light" ? "Dark" : "Light"}
      </Button> */}
    </>
  );
};

export default PreviewButton;
