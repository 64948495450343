import * as React from "react";

import { FaSearch } from "react-icons/fa";
import {
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  useColorModeValue as mode,
} from "@chakra-ui/react";

const SearchField = ({ placeHolder = "Search", ...props }) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon as={FaSearch} opacity={0.82}  color="#CACACA" />
      </InputLeftElement>
      <Input
        placeholder="Search"
        background="#FFF"
        borderWidth="1px"
        focusBorderColor="rgb(160,168,185,0.6)"
        borderColor="rgba(202, 202, 202, 0.5)"
        _placeholder="#CACACA"
        //opacity={0.60}
        borderRadius="lg"
        {...props}
      />
    </InputGroup>
  );
};

export default SearchField;
