import { Flex, Wrap, WrapItem } from '@chakra-ui/react';
import PreviewButton  from 'components/Button/PreviewButton';
import MessageButton from 'components/Button/MessageButton';
import React from 'react';
import NotificationButton from 'components/Button/NotifcationButton';
import { Avatar } from 'antd';

const TopRightSection = () => {
  return (
    <Flex
      position={'absolute'}
      right={30}
      top={1}
      // w="100%"
      flexDir="row"
      alignItems="flex-start"
      justifyContent="flex-end"
    >
      <PreviewButton m={2} />
      <NotificationButton m={2} />
      <MessageButton m={2} />
      <Wrap m={3.5}>
        <WrapItem>
            <Avatar  name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
        </WrapItem>
    </Wrap>
      {/* <NotificationTabs /> */}
      {/* Todo : Add Image */}
    </Flex>
  );
};

export default TopRightSection;
