import React, { useEffect, useState } from "react";
import { Button, Input, Text, Flex, Box, Select } from "@chakra-ui/react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import axios from "axios";
import { BASE_URL } from "constants/api";

const CustomSqlEditor = ({
  visualizerIsVisible,
  setVisualizerIsVisible,
  accessToken,
  queryText,
  setQueryText,
}) => {

  const [sqlTransformation, setSQLTransformation] = useState([])
  const [operation, setOperation] = useState(null)

  useEffect(() => {
    const res = axios
      .get(`${BASE_URL}/available_sql_transformations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setSQLTransformation(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      {/* <Button
        pos="absolute"
        top={20}
        right={50}
        zIndex={10}
        bg="white"
        borderWidth={1}
        boxShadow="xl"
        onClick={() => setVisualizerIsVisible(!visualizerIsVisible)}
      >
        Switch Back
      </Button> */}

      <Box w="100%" alignSelf="center" alignItems="flex-start">
        <Flex align="center" justify="center" gap={5}>
          <Text>SELECT</Text>
          <Input variant="flushed" w="20%"></Input>
        </Flex>
        <Flex align="center" justify="center" gap={5} mt={2}>
          <Text>FROM</Text>
          <Input variant="flushed" w="20%"></Input>
        </Flex>

        <Flex
          align="center"
          justify="center"
          gap={5}
          mt={2}
          alignSelf="center"
          ml="auto"
          mr="auto"
        >
          <Text>Operation</Text>
          <Select w="20%" placeholder="Select Operation"
            alignSelf="flex-start"
            variant="flushed"
            mt={2} 
            onChange={(event)=>setOperation(event.target.value) }
          >
            {sqlTransformation.map((operation) => (
              <option value={operation}>{operation}</option>
            ))}
          </Select>
          
        </Flex>

        <Flex align="center" justify="center" gap={5} mt={2}>
          <Text>ON</Text>
          <Input variant="flushed" w="20%"></Input>
        </Flex>
      </Box>
    </>
  );
};

export default CustomSqlEditor;
