import { Button,Text } from "@chakra-ui/react";
import React from "react";
import { IoFilter } from "react-icons/io5";

const FilterButton = ({ variant, children, ...props }) => {
  return (
    <Button bgColor="#F9FAFC" {...props}>
      <IoFilter style={{ marginRight: 8 }} size="20px" /> <Text>Filter</Text>
    </Button>
  );
};

export default FilterButton;
